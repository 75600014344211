import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useUserAuth } from "../../../AuthContext";
import DepartmentCard from "../../../features/departments/components/DepartmentCard";
import DepartmentForm from "../../../features/departments/components/DepartmentForm";
import { Department } from "../../../features/departments/models/department";
import departmentsService from "../../../features/departments/services/departments.service";
import globalStyles from "../../../global.module.css";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import CustomDialog from "../../../shared/CustomDialog";
import styles from "./departments.module.css";

export default function DepartmentsDashboard() {
  const navigation = useNavigate();
  const { setLoading } = useLoader();
  const auth = useAuth();
  const { userInfo } = useUserAuth();
  const [isDepthas, setIsDepthas] = React.useState(0);
  const [department, setDepartment] = React.useState(new Department());
  const [allDepartments, setAllDepartments] = React.useState<Department[]>([]);
  const [openAddDepartment, setOpenAddDepartment] = React.useState(false);
  const [AddDept, setcanAddDept] = React.useState<boolean>(true);

  useEffect(() => {
    if (userInfo && auth) refresh();
  }, [userInfo, auth]);

  useEffect(() => {
    const foundRole = userInfo?.departmentsRoles?.find(
      (role: { id: string | undefined }) => role?.id === department.id
    );
    if (foundRole?.rol === "VIEWER" || foundRole?.rol === "COOWNER") {
      setcanAddDept(false);
    } else {
      setcanAddDept(true);
    }
  }, [userInfo?.departmentsRoles, department.id]);

  const refresh = async () => {
    if (!userInfo || !auth) return;
    setLoading(true);
    let allDepartments: any[] = await departmentsService.getAll();
    if (allDepartments.length > 0) {
      let d = await departmentsService.findOneWithLatestFieldValues(
        allDepartments[0].id
      );
      if (d) setDepartment(d);
      setAllDepartments(allDepartments);
    } else {
      navigation("/home/my-department");
    }
    setIsDepthas(1);
    setLoading(false);
  };

  const handleInputChange = async (event: any) => {
    let d = await departmentsService.findOneWithLatestFieldValues(
      event?.target?.value
    );
    if (d) {
      setDepartment(d);
      if (userInfo?.departmentsRoles && event.target.value) {
        const foundRole = userInfo?.departmentsRoles?.find(
          (role: { id: string | undefined }) =>
            role?.id === event?.target?.value
        );
        if (foundRole?.rol === "VIEWER" || foundRole?.rol === "COOWNER") {
          setcanAddDept(false);
        } else {
          setcanAddDept(true);
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      {allDepartments?.length > 0 ? (
        <>
          <FormControl fullWidth className={styles.formSelect}>
            <InputLabel id="demo-simple-select-label">Department</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Department"
              onChange={handleInputChange}
            >
              {allDepartments?.length > 0 &&
                allDepartments?.map((d, i) => {
                  return (
                    <MenuItem key={i} value={d.id}>
                      {d.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <DepartmentCard
            departmentId={department?.id}
            onChange={() => refresh()}
            pathName="home-dashboard"
          />
        </>
      ) : (
        <>
          {isDepthas === 1 ? (
            <>
              You don't have any department assigned, please create a new one or
              check your notifications.
              <Grid item xs={12}>
                <Link to={"/home/notifications"}>
                  <Button variant="text">Notifications</Button>
                </Link>
                <Button
                  variant="contained"
                  onClick={() => setOpenAddDepartment(true)}
                >
                  New Department
                </Button>
              </Grid>
            </>
          ) : (
            ""
          )}
        </>
      )}
      {AddDept === true && (
        <div className={globalStyles.fabStyle}>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => setOpenAddDepartment(true)}
          >
            <AddIcon />
          </Fab>
        </div>
      )}
      <CustomDialog
        open={openAddDepartment}
        onClose={() => {
          setOpenAddDepartment(false);
          refresh();
        }}
        title="New Department"
        content={
          <DepartmentForm
            allDepartments={allDepartments}
            onSave={() => {
              setOpenAddDepartment(false);
              refresh();
            }}
            department={new Department()}
            onClose={() => {
              refresh();
            }}
            onDelete={function (): void {}}
          />
        }
        footer={<></>}
      ></CustomDialog>
    </>
  );
}
