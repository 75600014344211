import { useEffect, useState } from "react";
import { useUserAuth } from "../../../AuthContext";
import CustomDialog from "../../../shared/CustomDialog";
import { Department } from "../../departments/models/department";
import InvitationSendButton from "../../invitations/InvitationSendButton";
import UserRolesList from "./UserRolesList";

type UserRolesListProps = {
  open: boolean;
  department: Department;
  onChange: () => void;
  onClose: () => void;
  pathName: string;
};

export default function UsersRolesDialog(props: UserRolesListProps) {
  const { userInfo } = useUserAuth();
  const [canInvite, setcanInvite] = useState<boolean>(false);
  const [canDelete, setcanDelete] = useState<boolean>(false);

  useEffect(() => {
    if (props.department.userCanEdit(userInfo)) {
      setcanInvite(true);
      setcanDelete(true);
    }
  }, []);

  return (
    <>
      <CustomDialog
        open={props.open}
        onClose={props.onClose}
        title="Department Users"
        content={
          <>
            <UserRolesList
              department={props.department}
              onChange={() => {
                props.onChange();
              }}
              canDelete={canDelete}
              pathName={props.pathName}
            />
          </>
        }
        footer={
          <>
            <InvitationSendButton
              departmentId={props.department.id}
              onSend={() => {
                props.onChange();
              }}
              canInvite={canInvite}
            />
          </>
        }
      ></CustomDialog>
    </>
  );
}
