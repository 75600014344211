import React, { createContext, useCallback, useContext, useState } from "react";
import Loader from "./Loader";

// Step 1: Create a new context for the loader
const LoaderContext = createContext<any>({});

type Props = {
  children: React.ReactNode;
};

export const LoaderProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const value = {
    isLoading,
    setLoading,
  };

  return (
    <LoaderContext.Provider value={value}>
      <Loader />
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};
