
export const commonNumberInputValidation = {
  required: 'This field is required',
  minLength: {
    value: 1,
    message: 'This field is required',
  },
  maxLength: {
    value: 6,
    message: 'Maximum length is 6',
  },
  isNumber: {
    message: 'Must be a number',
  },
};
