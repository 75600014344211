import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorFormMsg } from "../../common/ErrorMessage";
import { useToast } from "../../layout/toast/ToastProvider";
import { CustomTextField } from "../../shared/CustomTextField";
import { OutlinedButton } from "../../shared/OutlinedButton";
import { PrimaryButton } from "../../shared/PrimaryButton";
import { TemplateGroup } from "./models/models";
import templatesGroupsService from "./services/templatesGroups.service";
type Props = {
  isOpen: boolean;
  templateGroup: TemplateGroup | undefined;
  onClose: () => void;
  onSave: (data: any) => void;
};

export default function TemplatesGroupEditDialog(props: Props) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { toastShow } = useToast();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (props.templateGroup) {
      reset(props.templateGroup);
      setIsEditing(true);
    } else {
      reset({
        name: "",
      });
      setIsEditing(false);
    }
  }, [props.templateGroup]);

  const onSubmit = async (data: any) => {
    let temp = data;
    if (!isEditing) {
      await templatesGroupsService.post({ data: temp }).then((resp: any) => {
        if (resp.status === 201) {
          toastShow({
            message: "Group " + data.name + " saved succesfully !",
            severity: "success",
          });

          props.onSave(resp.data);
        } else {
          toastShow({
            message: "Error saving group " + temp.name,
            severity: "error",
          });
        }
      });
    } else {
      await templatesGroupsService
        .update({ id: props.templateGroup!.id, data: temp })
        .then((resp: any) => {
          if (resp.status === 200) {
            toastShow({
              message: "Group " + temp.name + " updated succesfully !",
              severity: "success",
            });
            props.onSave(resp.data);
          } else {
            toastShow({
              message: "Error saving group " + temp.name,
              severity: "error",
            });
          }
        });
    }
    props.onClose();
    reset();
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} title="Save Template">
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Group Name
            </Typography>
            <Controller
              name="name"
              control={control}
              rules={{
                required: { value: true, message: 'This field is required' },
                validate: {
                  notEmpty: (value) => value.trim() !== '' || 'This field is required',
                  minLength: (value) => value.length >= 10 || 'Minimum length is 10 characters',
                  maxLength: (value) => value.length <= 30 || 'Maximum length is 30 characters',
                },
              }}
              render={({ field }) => (
                <CustomTextField label="" type="text" {...field}
                error={!!errors.name}
                />
              )}
            />
             {errors.name && <ErrorFormMsg message={errors.name?.message}/>} 
            <Grid item xs={12} mt={2}>
              <Controller
                name="isGlobal"
                control={control}
                defaultValue="false"
                render={({ field }) => (
                  <>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      Who can use this group?
                    </FormLabel>
                    <RadioGroup {...field} name="radio-buttons-group" defaultValue="false">
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Current User"
                      />
                    </RadioGroup>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            onClick={() => {
              props.onClose();
              reset();
            }}
          >
            Cancel
          </OutlinedButton>
          <PrimaryButton form="formMetric" onClick={handleSubmit(onSubmit)}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
