import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SpeedIcon from "@mui/icons-material/Speed";
import ViewListIcon from "@mui/icons-material/ViewList";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./layout.module.css";

export default function NavBottom() {
  const [selected, setSelected] = React.useState(0);
  const navigation = useNavigate();
  const handleLinkClick = (id: React.SetStateAction<number>) => {
    setSelected(id);
    const passPath =
      id === 0
        ? "/home/my-department"
        : id === 1
        ? "/home/departments"
        : id === 2
        ? "/home/graphs"
        : id === 3
        ? "/home/metrics"
        : "";
    navigation(passPath);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation value={selected} className={styles.bottomNavbar}>
        <BottomNavigationAction
          label=""
          icon={<HomeIcon />}
          onClick={(e) => handleLinkClick(0)}
          key={0}
        />

        <BottomNavigationAction
          label=""
          icon={<SpeedIcon />}
          onClick={(e) => handleLinkClick(1)}
          key={1}
        />

        <BottomNavigationAction
          label=""
          icon={<QueryStatsIcon />}
          onClick={(e) => handleLinkClick(2)}
          key={2}
        />
        <BottomNavigationAction
          label=""
          icon={<ViewListIcon />}
          onClick={(e) => handleLinkClick(3)}
          key={3}
        />
      </BottomNavigation>
    </Paper>
  );
}
