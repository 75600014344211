import BaseCRUD from "../common/baseCRUD";
import http from "../common/http-common";

class LogoutServices extends BaseCRUD {
    constructor() {
        super("/auth");
      }
  async LogoutUser(username?: any) {
    return http.post(this.urlBase + "/logout", { username: username});
  }
}

export default new LogoutServices();
