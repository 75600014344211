import AddBoxIcon from '@mui/icons-material/AddBox';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Grid, IconButton } from '@mui/material';
import React from 'react';
import SocialSharingModel from '../../../common/SocialSharing';
import globalStyle from '../../../global.module.css';
import { DepartmentEditPermission } from '../../departments/components/DepartmentEditPermissions';
import { Department } from '../../departments/models/department';
import { MetricA } from '../models/metric';
import MetricADialogForm from './MetricADialogEdit';
import MetricDialogInfo from './MetricAInfoDialog';
import MetricAValue from './MetricAValue';
import MetricAddValueDialogForm from './MetricAddValueDialog';
import styles from './metrics.module.css';

type MetricAListItemProps = {
  department: Department;
  metric: MetricA;
  showConfig?: boolean;
  onEditSave: () => void;
  onEditDelete: () => void;
  canShare?: boolean;
};

export default function MetricAOneLineInfo(props: MetricAListItemProps) {
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openAddMetricValue, setOpenAddMetricValue] =
    React.useState<boolean>(false);
  const [ShareDiolougeOpen, setShareDiolougeOpen] = React.useState(false);
  function handleSocialShareOpen() {
    setShareDiolougeOpen(true);
  }
  const closePoP = () => {
    setShareDiolougeOpen(false);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={styles.metricName}
        onClick={(ev) => setOpenInfo(true)}
      >
        <Grid item xs={6} className="align-right">
          <div>{props?.metric?.name?.substring(0, 80)}</div>
        </Grid>
        <Grid item xs={2} onClick={(ev) => setOpenInfo(true)}>
          <MetricAValue metric={props.metric} />
        </Grid>
        {props?.canShare && (
          <Grid item xs={1} mr={1}>
            <IconButton
              aria-label="share"
              onClick={(ev) => {
                ev.stopPropagation();
                handleSocialShareOpen();
              }}
            >
              <ShareIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={1}>
          <DepartmentEditPermission department={props.department}>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setOpenEdit(true);
                }}
              >
                <EditOutlinedIcon className={globalStyle.EditOutlinedIcon} />
              </IconButton>
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setOpenAddMetricValue(true);
                }}
              >
                <AddBoxIcon className={globalStyle.personAddAltOutlinedIcon} />
              </IconButton>
            </Box>
          </DepartmentEditPermission>
        </Grid>
      </Grid>

      <MetricADialogForm
        key={`editForm- ${props.metric.id}`}
        show={openEdit}
        metric={props.metric}
        department={props.department}
        onClose={() => {
          setOpenEdit(false);
        }}
        onSave={() => {
          setOpenEdit(false);
          props.onEditSave();
        }}
        onDelete={() => {
          setOpenEdit(false);
          props.onEditDelete();
        }}
      />

      <MetricAddValueDialogForm
        key={`valueForm- ${props.metric.id}`}
        show={openAddMetricValue}
        metric={props.metric}
        department={props.department}
        onClose={() => {
          setOpenAddMetricValue(false);
        }}
        onSave={() => {
          setOpenAddMetricValue(false);
          props.onEditSave();
        }}
      />

      <MetricDialogInfo
        open={openInfo}
        metric={props.metric}
        onClose={() => {
          setOpenInfo(false);
        }}
        department={props.department}
      />
      {ShareDiolougeOpen ? (
        <SocialSharingModel
          open={ShareDiolougeOpen}
          metricData={props}
          closeDialog={closePoP}
        />
      ) : (
        ''
      )}
    </>
  );
}
