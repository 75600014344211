import { Box, Button, Divider, Link, List, ListItem, ListItemText, ThemeProvider, Typography, createTheme, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MessageCount } from "../GlobalContext";
import invitationsService from "../features/invitations/services/invitations.service";
import { EnumNotificationType, Notification } from "../features/notifications/models/notification";
import notificationService from "../features/notifications/services/notification.service";
import { useToast } from "../layout/toast/ToastProvider";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import { convertISOStringToMonthDayOnlyDate } from "./util/date";

const CustomScrollbarDiv = styled("div")({
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px !important",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bfbfc1 !important",
    borderRadius: "5px !important",
    height: "50px !important", 
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff !important",
  borderRadius: "8px !important",
  },
});
const theme = createTheme();
function NotificationPopup() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsData, setNotificationsData] = useState<any>();
  const { count, setcount }:any = useContext(MessageCount);
  useEffect(() => {
    refresh();
  }, []);
  const refresh = async () => {
    let noti = await notificationService.getMy();
    if (noti) setNotifications(noti?.sort((a:any, b:any) => b.id - a.id));
  };

  const { toastShow } = useToast();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);

  const handleAcceptNotification = (notification: Notification) => {
    invitationsService.accept(notification.typeRefUID).then((res) => {
      if(res?.response?.status === 400){
        toastShow({ message: "Unable to join the department. The invitation has expired.", severity: "info" });
      }else{
        refresh();
        toastShow({ message: "Invitation accepted", severity: "success" });
        setcount(count-1);
      }
    });
  };

  const setOpenDeclineDeleteConfirmation = (data : Notification) => {
    setNotificationsData(data);
    setOpenDeleteConfirmation(true);
  }

  const handleDeclineNotification = (notification: Notification) => {
    invitationsService.decline(notification.typeRefUID).then((res) => {
      refresh();
      toastShow({ message: "Invitation declined", severity: "info" });
      setcount(count-1);
    });
  };

  const  handleClearNotification = (notification: Notification) => {
    invitationsService.clear(notification).then((res) => {
      refresh();
      setcount(count-1);
    });
  }

  return (
    <>
    <ThemeProvider theme={theme}>
    <CustomScrollbarDiv
      style={{
        position: "absolute",
        top: "44px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: "rgb(246,246,246)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.32)",
        zIndex: 1000,
        width: "60%",
        left: "113px",
      }}
    >
    <Box pt={0.8} pb={0.8} pl={2} pr={2} sx={{display: 'flex', justifyContent: "space-between" , background:'white'}}>
      <Typography sx={{fontSize:"15px", fontWeight:'bold'}} >Notifications</Typography>
      {notifications.length>3? <Box textAlign={'center'}>
      <Link
        component="button"
        variant="body2"
      >
        <NavLink to={"/home/notifications"}>View all</NavLink>
      </Link>
      </Box>:null}</Box>
    <List
      sx={{
        width: '100%',
        maxWidth: 400,
        position: 'relative',
        maxHeight: 300,
        '& ul': { padding: 0 },
        backgroundColor:"white"
      }}
      subheader={<li />}
      >
        <Divider  />
      {notifications && notifications.slice(0,5)?.map((invitations:any) => (
      <Box sx={{background:'white',    margin: '10px'}}>
      <ListItem alignItems="flex-start"  sx={{p:0, paddingLeft:1}}>
       <ListItemText
         primary={<Typography component={'span'}  m={0} sx={{fontSize: '15px' }}>{invitations?.title}</Typography>}
         secondary={
          <div dangerouslySetInnerHTML={{ __html: invitations?.message }} style={{fontSize:'12px', color:"black"}}/>
         }
         sx={{
        m:0
         }}
       />
     </ListItem>
      {!(invitations?.title ==="Invitation  Declined"  || invitations?.title ==="Invitation Accepted")?(<>
      {invitations.type === EnumNotificationType.INVITATION ? (
       <Box m={0} mb={1} p={0} display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Button sx={{fontSize:'10px', fontWeight:'bold'}}
              onClick={()=> handleAcceptNotification(invitations)}
              >Accept</Button>
              <Button color="error"  sx={{fontSize:'10px',  fontWeight:'bold'}}
                onClick={() => setOpenDeclineDeleteConfirmation(invitations)}
              >
              Decline
            </Button>
          </Box>
          <Typography fontSize={'13px'} mt={0.5}>{convertISOStringToMonthDayOnlyDate(invitations?.createDateTime)}</Typography>
      </Box>
      ) : null}
       </>):
          <Box sx={{display:"flex",width:"100%",justifyContent:"space-between" }}>
          <Button sx={{fontSize:'10px', fontWeight:'bold'}} onClick={()=>handleClearNotification(invitations)}>Clear</Button>
          <Typography fontSize={'14px'} mt={0.9}>{convertISOStringToMonthDayOnlyDate(invitations.createDateTime)}</Typography>
        </Box>
      }
      <Divider/>
    </Box>
      ))}
      </List>
      {notifications?.length < 1 ? <Box p={3} textAlign={'center'}><Typography>Not yet any notifications!</Typography></Box>:""}
      </CustomScrollbarDiv>
    </ThemeProvider>
    <ConfirmationDialog
    open={openDeleteConfirmation}
    onClose={() => {
      setOpenDeleteConfirmation(false);
    }}
    message={"Are you sure to decline this invitation?"}
    onAccept={() => {
      setOpenDeleteConfirmation(false);
      handleDeclineNotification(notificationsData);
    }}
  />
  </>
  )
}

export { NotificationPopup };
