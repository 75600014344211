import AddBoxIcon from "@mui/icons-material/AddBox";
import EditOutlined from "@mui/icons-material/EditOutlined";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  useTheme,
} from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import globalStyles from "../../global.module.css";
import TemplatesGroupEditDialog from "./TemplateGroupsEdit";
import { TemplateGroup } from "./models/models";
import templatesGroupsService from "./services/templatesGroups.service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  id: string,
  selectedTemplatesGroups: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      selectedTemplatesGroups.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Props {
  onSelect: (templates: TemplateGroup[]) => void;
  isEditable: boolean;
  label: string;
  defaultvalue?: any;
  isError?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

const TemplateGroupsSelect = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const theme = useTheme();

    const [openEditGroup, setOpenEditGroup] = React.useState<boolean>(false);
    const [editingTemplateGroup, setEditingTemplateGroup] = React.useState<
      TemplateGroup | undefined
    >(undefined);

    const [allTemplatesGroups, setAllTemplatesGroups] = React.useState<
      TemplateGroup[]
    >([]);

    const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);
    const [newCreatedGroup, setnewCreatedGroup] = React.useState<any>({});

    useEffect(() => {
      refresh();
    }, []);

    useEffect(() => {
      if (!(Object.keys(newCreatedGroup).length === 0)) {
        setSelectedGroups([newCreatedGroup?.id]);
      }
    }, [newCreatedGroup]);

    const refresh = () => {
      templatesGroupsService.get().then((resp: any) => {
        if (resp.data) setAllTemplatesGroups(resp.data);
      });
    };

    useEffect(() => {
      props.onSelect(
        allTemplatesGroups.filter((g) => {
          if (g.id)
            if (selectedGroups.indexOf(g.id) !== -1) {
              return g;
            }
        })
      );
    }, [selectedGroups]);

    const handleChange = (event: any) => {
      setSelectedGroups(
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    };

    const getNameById = (id: string) => {
      return allTemplatesGroups.filter((g) => g.id === id)[0].name;
    };

    return (
      <>
        <Grid item xs={props.isEditable ? 10 : 12} ref={ref}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedGroups}
              onChange={handleChange}
              style={{ width: "100%" }}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Add to Groups"
                  style={{ width: "100%" }}
                />
              }
              renderValue={(selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((value: any) => (
                    <Chip key={value} label={getNameById(value)} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {allTemplatesGroups?.map((templateGroup) => {
                return (
                  <MenuItem
                    key={templateGroup?.id}
                    value={templateGroup?.id}
                    style={getStyles(
                      templateGroup?.id!,
                      selectedGroups?.map((g: any) => g.id),
                      theme
                    )}
                  >
                    <Grid item xs={11}>
                      {templateGroup?.name}
                    </Grid>
                    {props.isEditable ? (
                      <Grid item xs={1}>
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setOpenEditGroup(true);
                            setEditingTemplateGroup(templateGroup);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Grid>
                    ) : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        {props.isEditable ? (
          <Grid item xs={1}>
            <IconButton
              onClick={(ev) => {
                setOpenEditGroup(true);
                setEditingTemplateGroup(undefined);
              }}
            >
              <AddBoxIcon className={globalStyles.personAddAltOutlinedIcon} />
            </IconButton>
          </Grid>
        ) : null}

        <TemplatesGroupEditDialog
          isOpen={openEditGroup}
          templateGroup={editingTemplateGroup}
          onClose={function (): void {
            setOpenEditGroup(false);
          }}
          onSave={function (data: TemplateGroup): void {
            setnewCreatedGroup(data);
            setOpenEditGroup(false);
            refresh();
          }}
        />
      </>
    );
  }
);
export default TemplateGroupsSelect;
