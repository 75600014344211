import { useContext, useEffect, useState } from "react";
import { MessageCount } from "../../../GlobalContext";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import { useToast } from "../../../layout/toast/ToastProvider";
import InvitationsService from "../../invitations/services/invitations.service";
import { Notification } from "../models/notification";
import NotificationService from "../services/notification.service";
import NotificationItem from "./NotificationItem";

export default function NotificationsList() {
  const { toastShow } = useToast();
  const { setLoading } = useLoader();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { count, setcount }:any = useContext(MessageCount);

  useEffect(() => {
    refresh();
  }, [count]);

  const refresh = async () => {
    setLoading(true);
    let noti = await NotificationService.getMy();
    if (noti) setNotifications(noti?.sort((a:any, b:any) => b.id - a.id));
    setLoading(false);
  };

  const handleAcceptNotification = (notification: Notification) => {
    InvitationsService.accept(notification.typeRefUID).then((res) => {
      if(res?.response?.status === 400){
        toastShow({ message: "Unable to join the department. The invitation has expired.", severity: "info" });
      }else{
        refresh();
        toastShow({ message: "Invitation accepted", severity: "success" });
        setcount(count-1);
      }
    });
  };

  const handleDeclineNotification = (notification: Notification) => {
    InvitationsService.decline(notification.typeRefUID).then((res) => {
      toastShow({ message: "Invitation declined", severity: "info" });
      setcount(count-1);
      refresh();
    });
  };

  const  handleClearNotification = (notification: Notification) => {
    InvitationsService.clear(notification).then((res) => {
      refresh();
      setcount(count-1);
    });
  }

  return (
    <>
      {notifications.length === 0 && (
        <div className="text-center">You don't have notifications</div>
      )}
      {notifications.map((notification: Notification, i) => {
        return (
          <NotificationItem
            key={i}
            notification={notification}
            handleAccept={() => handleAcceptNotification(notification)}
            handleDecline={() => handleDeclineNotification(notification)}
            handleClear = {() => handleClearNotification(notification)}
          />
        );
      })}
    </>
  );
}
