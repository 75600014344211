import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useUserAuth } from "../../../AuthContext";
import { ErrorFormMsg } from "../../../common/ErrorMessage";
import { commonNumberInputValidation } from "../../../common/util/validatiinUtillss";
import { useToast } from "../../../layout/toast/ToastProvider";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import CustomDialog from "../../../shared/CustomDialog";
import { CustomTextButton } from "../../../shared/CustomTextButton";
import { CustomTextField } from "../../../shared/CustomTextField";
import { OutlinedButton } from "../../../shared/OutlinedButton";
import { PrimaryButton } from "../../../shared/PrimaryButton";
import { Department } from "../../departments/models/department";
import TemplateSaveDialog from "../../templates/TemplateSaveDialog";
import { MetricA } from "../models/metric";
import metricsAService from "../services/metricsA.service";
import styles from "./metrics.module.css";

type MetricADialogProps = {
  show: boolean;
  metric: MetricA;
  department: Department;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
  metricdata?: any;
};

export default function MetricADialogForm(props: MetricADialogProps) {
  const { toastShow } = useToast();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const [title, setTitle] = React.useState<string>("New Metric");
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const { userInfo } = useUserAuth();

  const [openTemplateSave, setOpenTemplateSave] =
    React.useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);

  const [templateData, setTemplateData] = React.useState<any>([]);

  const handleTemplateDataChange = (data: any) => {
    setTemplateData(JSON.parse(data));
  };

  useEffect(() => {
    updateState();
  }, [props.metric, props.department]);

  React.useEffect(() => {
    register("id");
    register("departmentId");
    setTemplateData([]);
  }, [register]);

  const updateState = () => {
    if (props.metric.id) {
      setTitle("Edit Metric");
      setIsEditing(true);
    } else {
      setTitle("New Metric");
      setIsEditing(false);
      const MatricData: any = localStorage.getItem("MatricData");
      if (MatricData) {
        const data: any = JSON.parse(MatricData ? MatricData : "");
        if (data) {
          setTimeout(() => {
            setValue("name", data?.name);
            setValue("currentValue", data?.currentValue);
            setValue("minThresholdValue", data?.minThresholdValue);
            setValue("maxThresholdValue", data?.maxThresholdValue);
            setValue("goodAtLowerThreshold", data?.goodAtLowerThreshold);
            setValue("weighting", data?.weighting);
            setValue("penalty", data?.penalty);
            setValue("description", data?.description);
            setValue("departmentId", data?.id ? data?.id : props.department.id);
            setValue("showColor", true);
            setValue("redZone", data?.redZone);
            setValue("greenZone", data?.greenZone);
          }, 500);
        }
      }
    }
    setValue("id", props.metric.id);
    setValue(
      "name",
      templateData?.name ? templateData?.name : props.metric.name
    );
    setValue(
      "currentValue",
      templateData?.currentValue
        ? templateData?.currentValue
        : props.metric.currentValue
    );
    setValue(
      "minThresholdValue",
      templateData?.minThresholdValue
        ? templateData?.minThresholdValue
        : props.metric.minThresholdValue
    );
    setValue(
      "maxThresholdValue",
      templateData?.maxThresholdValue
        ? templateData?.maxThresholdValue
        : props.metric.maxThresholdValue
    );
    setValue(
      "goodAtLowerThreshold",
      templateData?.goodAtLowerThreshold
        ? templateData?.goodAtLowerThreshold
        : props.metric.goodAtLowerThreshold
    );
    setValue(
      "weighting",
      templateData?.weighting ? templateData?.weighting : props.metric.weighting
    );
    setValue(
      "penalty",
      templateData?.penalty ? templateData?.penalty : props.metric.penalty
    );
    setValue(
      "description",
      templateData?.description
        ? templateData?.description
        : props.metric.description
    );
    setValue(
      "departmentId",
      templateData?.departmentId
        ? templateData?.departmentId
        : props.department.id
    );
    setValue(
      "showColor",
      templateData?.showColor ? templateData?.showColor : true
    );
    setValue(
      "redZone",
      templateData?.redZone ? templateData?.redZone : props.metric.redZone
    );
    setValue(
      "greenZone",
      templateData?.greenZone ? templateData?.greenZone : props.metric.greenZone
    );
  };

  const onSubmit = async (data: any) => {
    if (!props?.metric?.id) {
      localStorage.setItem("MatricData", JSON.stringify(data));
    }
    if (!validateZone()) return;
    if (data.id) {
      const result = await metricsAService.update(data);
      if (result?.status === 400) {
        toastShow({
          message: <strong>Metric {data.name} allready exists !</strong>,
          severity: "error",
        });
        return false;
      }
      toastShow({
        message: "Metric " + data.name + " updated succesfully !",
        severity: "success",
      });
    } else {
      const result = await metricsAService.createNew(data);
      if (result?.status === 400) {
        toastShow({
          message: <strong>Metric {data.name} allready exists !</strong>,
          severity: "error",
        });
        return false;
      }
      toastShow({
        message: "Metric " + data.name + " created succesfully !",
        severity: "success",
      });
    }
    localStorage.setItem("MatricData", "");
    setTemplateData([]);
    props.onSave();
  };

  const validateZone = () => {
    if (
      Number(watch("redZone")) < Number(watch("minThresholdValue")) ||
      Number(watch("redZone")) > Number(watch("maxThresholdValue"))
    ) {
      toastShow({
        message: "Red Zone is not in the threshold range",
        severity: "error",
      });
      return false;
    } else if (
      Number(watch("greenZone")) < Number(watch("minThresholdValue")) ||
      Number(watch("greenZone")) > Number(watch("maxThresholdValue"))
    ) {
      toastShow({
        message: "Green Zone is not in the threshold range",
        severity: "error",
      });
    } else {
      if (watch("goodAtLowerThreshold") === "false") {
        if (Number(watch("redZone")) <= Number(watch("greenZone"))) {
          toastShow({
            message: "Red Zone must be greather than Green Zone",
            severity: "error",
          });
          return false;
        }
        return true;
      } else {
        if (Number(watch("redZone")) >= Number(watch("greenZone"))) {
          toastShow({
            message: "Red Zone must be less than the Green Zone.",
            severity: "error",
          });
          return false;
        }
        return true;
      }
    }
  };

  const delete_ = async () => {
    await metricsAService.delete(watch("id"));
    toastShow({
      message: `Metric  ${watch("name")}  deleted succesfully !`,
      severity: "info",
      duration: 1000,
    });
    props.onDelete();
  };

  const getValuesAsMetric = () => {
    return {
      id: watch("id"),
      name: watch("name"),
      currentValue: watch("currentValue"),
      minThresholdValue: watch("minThresholdValue"),
      maxThresholdValue: watch("maxThresholdValue"),
      goodAtLowerThreshold: watch("goodAtLowerThreshold"),
      weighting: watch("weighting"),
      penalty: watch("penalty"),
      description: watch("description"),
      departmentId: watch("departmentId"),
      showColor: watch("showColor"),
      redZone: watch("redZone"),
      greenZone: watch("greenZone"),
      categories: [],
      beforeValue: 0,
    };
  };
  const result = userInfo?.departmentsRoles.find(
    (item: { id: any }) => item.id === props.department.id
  );

  const handleOpenTemplateDialog = () => {
    localStorage.setItem(
      "MatricData",
      JSON.stringify({
        name: getValues("name"),
        currentValue: getValues("currentValue"),
        minThresholdValue: getValues("minThresholdValue"),
        maxThresholdValue: getValues("maxThresholdValue"),
        goodAtLowerThreshold: getValues("goodAtLowerThreshold"),
        weighting: getValues("weighting"),
        penalty: getValues("penalty"),
        description: getValues("description"),
        departmentId: getValues("departmentId"),
        showColor: true,
        redZone: getValues("redZone"),
        greenZone: getValues("greenZone"),
      })
    );
    if (watch("name") === "") {
      toastShow({
        message: "Metric name field is required.",
        severity: "error",
      });
      return false;
    } else {
      if (!validateZone()) {
        return;
      } else {
        setOpenTemplateSave(true);
      }
    }
  };

  return (
    <>
      <CustomDialog
        open={props.show}
        onClose={props.onClose}
        title={title}
        content={
          <>
            <form id="formMetric">
              <Card variant="outlined" sx={{ marginTop: 1 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          validate: {
                            notEmpty: (value) =>
                              value.trim() !== "" || "This field is required",
                            minLength: (value) =>
                              value.length >= 5 ||
                              "Minimum length is 5 characters",
                            maxLength: (value) =>
                              value.length <= 75 ||
                              "Maximum length is 75 characters",
                          },
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            label="Metric Name"
                            type="text"
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                      {errors.name && (
                        <ErrorFormMsg message={errors.name?.message} />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="currentValue"
                        control={control}
                        rules={{
                          ...commonNumberInputValidation,
                          validate: {
                            isNumber: (value) =>
                              !isNaN(value) ||
                              commonNumberInputValidation.isNumber.message,
                          },
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            label="Actual Value"
                            type="number"
                            {...field}
                            fullWidth
                          />
                        )}
                      />
                      {errors.currentValue && (
                        <ErrorFormMsg message={errors.currentValue?.message} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ marginTop: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 1,
                    m: 1,
                  }}
                >
                  <div>
                    <Typography style={{ marginTop: "15px" }}>
                      Create a new template click here (Optional)
                      <ArrowRightAltIcon />{" "}
                    </Typography>
                  </div>
                  <div>
                    <CustomTextButton
                      className={styles.templateMenuIcon}
                      onClick={() => {
                        handleOpenTemplateDialog();
                      }}
                    >
                      <SaveOutlinedIcon sx={{ fontSize: 30 }} />
                    </CustomTextButton>
                  </div>
                </Box>
                <CardContent>
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <Controller
                      name="minThresholdValue"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          sx={{ width: 220 }}
                          label="Min Threshold "
                          type="number"
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name="maxThresholdValue"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          sx={{ marginLeft: 5, width: 220 }}
                          label="Max Threshold "
                          type="number"
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                  <Typography
                    fontSize={14}
                    mb={1}
                    mt={1}
                    className={styles.greenSpan}
                  >
                    You can still enter values outside of this range, but for
                    calculating the health score, we restrict the value to this
                    range.
                  </Typography>
                  <Controller
                    name="goodAtLowerThreshold"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} name="radio-buttons-group">
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Lower is better"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Higher is better"
                        />
                      </RadioGroup>
                    )}
                  />
                  <Typography
                    sx={{ fontSize: 14, mt: 1 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Red Zone
                  </Typography>
                  <Controller
                    name="redZone"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField label="" type="number" {...field} />
                    )}
                  />
                  <Typography
                    sx={{ fontSize: 14, mt: 1 }}
                    gutterBottom
                    className={styles.greenSpan}
                  >
                    The Metric will be in the red zone if the value is{" "}
                    {watch("goodAtLowerThreshold") === "false"
                      ? "higher"
                      : "lower"}{" "}
                    than {watch("redZone")}
                  </Typography>

                  <Typography
                    mt={2}
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Green Zone
                  </Typography>
                  <Controller
                    name="greenZone"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField label="" type="number" {...field} />
                    )}
                  />
                  <Typography
                    sx={{ fontSize: 14 }}
                    className={styles.greenSpan}
                    gutterBottom
                  >
                    The Metric will be in the green zone if the value is{" "}
                    {watch("goodAtLowerThreshold") === "false"
                      ? "lower"
                      : "higher"}{" "}
                    than {watch("greenZone")}
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Metric weight in the department
                  </Typography>
                  <Controller
                    name="weighting"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField label="" type="number" {...field} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ marginTop: 1 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Description
                  </Typography>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        style={{
                          border: "1px solid #fff3f3",
                          width: 500,
                          height: 80,
                          backgroundColor: "rgb(169 195 236 / 15%)",
                        }}
                        aria-label="minimum height"
                        placeholder="Minimum 3 rows"
                        {...field}
                        maxRows={3}
                      />
                    )}
                  />
                </CardContent>
              </Card>

              {isEditing && result?.rol === "OWNER" ? (
                <CustomTextButton
                  onClick={() => {
                    setOpenDeleteConfirmation(true);
                  }}
                >
                  <DeleteOutlinedIcon sx={{ fontSize: 16 }} />
                  Delete
                </CustomTextButton>
              ) : null}
            </form>
            {props.show ? (
              <>
                <TemplateSaveDialog
                  metric={getValuesAsMetric()}
                  isOpen={openTemplateSave}
                  onClose={() => {
                    setOpenTemplateSave(false);
                  }}
                  onTemplateDataChange={handleTemplateDataChange}
                />
                <ConfirmationDialog
                  open={openDeleteConfirmation}
                  onClose={() => {
                    setOpenDeleteConfirmation(false);
                  }}
                  message={"Are you sure to delete this metric?"}
                  onAccept={() => {
                    setOpenDeleteConfirmation(false);
                    delete_();
                  }}
                />
              </>
            ) : null}
          </>
        }
        footer={
          <>
            <OutlinedButton
              onClick={() => {
                reset();
                props.onClose();
              }}
            >
              Cancel
            </OutlinedButton>
            <PrimaryButton form="formMetric" onClick={handleSubmit(onSubmit)}>
              Save
            </PrimaryButton>
          </>
        }
      ></CustomDialog>
    </>
  );
}
