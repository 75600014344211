import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type CustomDialogProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactElement<any, any>;
  content?: React.ReactElement<any, any>;
  footer?: React.ReactElement<any, any>;
  title?: string;
};

export const BottomDialog = styled(Dialog)((props: any) => ({
  ".MuiPaper-root.MuiDialog-paper": {
    bottom: "-30px",
    height: "inherit",
    width: "100%",
    padding: "30px",
    paddingBottom: "50px",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    margin: "0px",
  },

  ".MuiDialogTitle-root": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    color: "#3461fd",
    fontWeight: "900",
  },

  ".MuiDialogContent-root": {
    padding: "30px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },

  ".MuiDialogActions-root": {
    padding: "0px",
    marginTop: "25px",
  },
}));

export default function CustomDialog(props: CustomDialogProps) {
  return (
    <>
      <BottomDialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {props.title ? props.title : null}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>{props.footer}</DialogActions>
      </BottomDialog>
    </>
  );
}
