import { Metric, MetricA } from "../../metrics/models/metric";
import { MetricDTO } from "../../metrics/models/metric-dto";
import { Rol } from "../../users/models/user";
import { DepartmentDTO } from "./department-dto";

export enum ValueColors {
  green = "#00bc9b",
  yellow = "#dbdd55",
  red = "#dd556e",
}

export interface IUserRol {
  id: string;
  rol: string;
  email: string;
  name: string;
}

export interface IDepartment {
  id?: string;
  name: string;
  parentDepartmentId?: string;
  goodThreshold: number;
  badThreshold: number;
  children?: Array<IDepartment>;
  metrics: Array<Metric>;
  usersRoles: Array<any>;
}

export class Department implements IDepartment {
  public id?: string;
  public name: string = "Department Prototype";
  public parentId?: string | undefined;
  public goodThreshold: number = 90;
  public badThreshold: number = 70;
  public children?: IDepartment[] | undefined;
  public metrics: MetricA[] = [];
  public usersRoles: any[] = [];
  public userNotificationSubscribed: boolean = false;

  static transformFromDTO(data: DepartmentDTO): Department {
    let department: Department = new Department();

    if (!data.id) return department;

    department.id = data.id;
    department.name = data.name;

    if (data.parent) department.parentId = data.parent.id;

    if (data.usersRoles && data.usersRoles.length > 0) {
      data.usersRoles.forEach((userRol) => {
        department.usersRoles.push({
          id: userRol.id,
          rol: userRol.rol,
          email: userRol.user.email,
          name: userRol.user.name,
          userId: userRol.user.id,
        });
      });
    }

    if (data.metrics && data.metrics.length > 0) {
      data.metrics.forEach((metric: MetricDTO) => {
        const owner = data.usersRoles?.find(
          (userRol) => userRol.rol === "OWNER"
        )?.user;

        let metricA = MetricA.transformFromDTO(department.id, metric);

        if (owner) {
          metricA.owner.email = owner.email;
        }

        department.metrics.push(metricA);
      });
    }
    return department;
  }

  public getHelathScore(): number {
    let totalScore: number = 0;
    let departmentTotalWeight: number = 0;

    this.metrics.forEach((metric: MetricA) => {
      departmentTotalWeight += Number(metric?.weighting);
    });

    this.metrics.forEach((metric: MetricA) => {
      totalScore += metric.getWeightedScore(departmentTotalWeight);
    });
    return Math.round(totalScore * 100);
  }

  public goodAtLowerThreshold(): boolean {
    return this.goodThreshold < this.badThreshold;
  }

  getScoreColor(): string {
    const healthScore = this.getHelathScore();
    if (this.goodAtLowerThreshold()) {
      if (healthScore > this.badThreshold) {
        return ValueColors.red;
      }

      if (healthScore < this.goodThreshold) {
        return ValueColors.green;
      }
    } else {
      if (healthScore > this.goodThreshold) {
        return ValueColors.green;
      }

      if (healthScore < this.badThreshold) {
        return ValueColors.red;
      }
    }
    return ValueColors.yellow;
  }

  getUserRoles(id: string): string[] {
    const userRol = this.usersRoles.filter((userRol) => {
      if (!userRol.userId) return false;
      return userRol.userId === id;
    });
    return userRol.map((userRol) => userRol.rol);
  }

  userCanEdit(user: any): boolean {
    let res =
      this.getUserRoles(user?.id).includes(Rol.OWNER) ||
      this.getUserRoles(user?.id).includes(Rol.COOWNER);
    return res;
  }

  userIsOwner(user: any): boolean {
    let res = this.getUserRoles(user?.id).includes(Rol.OWNER);
    return res;
  }
}
