import BaseCRUD from "../../../common/baseCRUD";
import http from "../../../common/http-common";

class TemplatesGroupsService extends BaseCRUD {
  constructor() {
    super("/templates-groups");
  }

  async getAllTemplatesFrom(ids: string[]) {
    return await http.post(this.urlBase + "/templates", { data: ids });
  }
}

export default new TemplatesGroupsService();
