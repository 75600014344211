import { useUserAuth } from "../../../AuthContext";
import { Department } from "../models/department";

export interface ViewDepartmentUserListProps {
  department: Department;
  children: any;
}

export const DepartmentViewUserListPermission = ({
  department,
  children,
}: ViewDepartmentUserListProps) => {
  const { userInfo } = useUserAuth();
  if (department.userCanEdit(userInfo)) {
    return children;
  } else {
    return null;
  }
};
