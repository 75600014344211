import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { convertISOStringToMonthDayOnlyDate } from "../../../common/util/date";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { EnumNotificationType, Notification } from "../models/notification";
import styles from "./notification.module.css";

type NotificationItemProps = {
  notification: Notification;
  handleAccept: () => void;
  handleDecline: () => void;
  handleClear:() =>void;
};

export default function NotificationItem(props: NotificationItemProps) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);

  return (
    <>
      <Card sx={{ marginTop: 1 }}>
        <CardContent className={styles.paddingCard}>
        <Typography component={'span'}  ml={1.1} mb={2} sx={{fontSize: '18px' }}>{props.notification?.title}</Typography>
        <Typography
            sx={{ textAlign: "justify", padding: "0px 10px" }}
            className={styles.metricName}
            dangerouslySetInnerHTML={{ __html: props.notification.message }}
          />
        </CardContent>
        <CardActions>
          {!(props?.notification?.title ==="Invitation  Declined"  || props?.notification?.title ==="Invitation Accepted")?(<>
          {props.notification.type == EnumNotificationType.INVITATION ? (
            <Box ml={'13px'}   sx={{display:"flex",width:"100%",justifyContent:"space-between" }}>
            <Box>
              <Button onClick={props.handleAccept}>Accept</Button>
              <Button color="error" onClick={() => setOpenDeleteConfirmation(true)}>
                Decline
              </Button>
            </Box>
            <Box>
                <Typography fontSize={'14px'} mt={0.9}>{convertISOStringToMonthDayOnlyDate(props.notification.createDateTime)}</Typography>
            </Box>
          </Box>
          ) : null}
          </>):<Box ml={'13px'}   sx={{display:"flex",width:"100%",justifyContent:"space-between" }}>
            <Button onClick={props.handleClear}>Clear</Button>
            <Typography fontSize={'14px'} mt={0.9}>{convertISOStringToMonthDayOnlyDate(props.notification.createDateTime)}</Typography>
          </Box>}
        </CardActions>
      </Card>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false);
          props.handleAccept();
        }}
        message={"Are you sure to decline this invitation?"}
        onAccept={() => {
          setOpenDeleteConfirmation(false);
          props.handleDecline();
        }}
      />
    </>
  );
}
