import React, { useContext, useEffect } from "react";
import { MessageCount } from "../../GlobalContext";
import { Department } from "../departments/models/department";
import InvitationItem from "./invitationItem";
import { Invitation } from "./models/invitation";
import invitationsService from "./services/invitations.service";

type InvitationsListProps = {
  department: Department;
  canDelete: boolean;
  pathName: string;
};

export default function InvitationsList(props: InvitationsListProps) {
  const [invitations, setInvitations] = React.useState<Invitation[]>([]);
  const { count, setcount }: any = useContext(MessageCount);

  useEffect(() => {
    refresh();
  }, [props.department, count]);

  const refresh = async () => {
    setInvitations([]);

    if (props.department.id === undefined) return;
    if (props.pathName === "my-departments") {
      let invitations: any[] = await invitationsService.getAllByDepartment(
        props.department.id as string
      );
      if (invitations && invitations.length > 0) {
        setInvitations(invitations);
      }
    } else if (props.pathName === "home-dashboard") {
      let invitations: any[] = await invitationsService.getAllByDepartment(
        props.department.id as string
      );
      if (invitations && invitations.length > 0) {
        setInvitations(invitations);
      }
    } else {
      setInvitations([]);
    }
  };

  return (
    <>
      {invitations.map((invitation: Invitation, i) => {
        return (
          <InvitationItem
            key={i}
            invitation={invitation}
            updated={() => refresh()}
            canDelete={props.canDelete}
            departmentName={props.department?.name}
          />
        );
      })}
    </>
  );
}
