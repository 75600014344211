import AddBoxIcon from "@mui/icons-material/AddBox";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShareIcon from "@mui/icons-material/Share";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../AuthContext";
import SocialSharing from "../../../common/SocialSharing";
import { default as globalStyles } from "../../../global.module.css";
import { Department } from "../../departments/models/department";
import { MetricA } from "../models/metric";
import MetricAddValueDialogForm from "./MetricAddValueDialog";
import MetricAValue from "./MetricAValue";
import styles from "./metrics.module.css";
type MetricInfoProps = {
  metric: MetricA;
  department: Department;
  onChartClick: () => void;
  onShareClick: () => void;
};

export default function MetricAInfo(props: MetricInfoProps) {
  const [ShareDiolougeOpen, setShareDiolougeOpen] = React.useState(false);
  const [openAddMetricValue, setOpenAddMetricValue] = React.useState<boolean>(false);
  const [metricData, setmetricData] = React.useState(props.metric);

  const { userInfo } = useUserAuth();
   //open close popup boxes
   function handleSocialShareOpen() {
    setShareDiolougeOpen(true);
  }
  const closePoP = () => {
    setShareDiolougeOpen(false);
  };

  const handleSave = (updatedMetricData: any) => {
    metricData.currentValue = updatedMetricData.currentValue;
    setmetricData(metricData)
    setOpenAddMetricValue(false);
  };

  return (
    <>
      <Card className={globalStyles.modalCard}>
        <CardContent>
          <div className={styles.modalContainer}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {props.department ? (
                  <Typography
                    sx={{
                      fontSize: 14,
                      paddingTop: 2,
                      textAlign: "left",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {props.department.name}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingTop: 1,
                    textAlign: "left",
                    fontWeight: "bold"  
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {props.metric.name}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{display: "grid", placeContent: "flex-end"}}>
                <MetricAValue metric={metricData}/>
                {/*props.metric.getValueGrow() > 0 ? (
                    <div className={styles.variationUp}>
                      <ArrowDropUpIcon /> {props.metric.getValueGrow()}
                    </div>
                  ) : (
                    <div className={styles.variationDown}>
                      <ArrowDropDownIcon /> {props.metric.getValueGrow()}
                    </div>
                  )*/}
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>Owner</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography component={'span'} sx={{ float: "right" }}>
                  {props.metric.owner.email}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "bold" }}>Min Threshold</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={{ float: "right" }}>
                  {props.metric.minThresholdValue}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "bold" }}>Max Threshold</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={{ float: "right" }}>
                  {props.metric.maxThresholdValue}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Good a lower threshold
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={{ float: "right" }}>
                  {props.metric.goodAtLowerThreshold ==="true" ?"Yes":"No"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "bold" }}>Last Updated</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={{ float: "right" }}>
                  {" "}
                  {props.metric.updateDateTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold" }}>Description</Typography>
              </Grid>
              <Grid item xs={12} sx={{paddingTop: 0,  textAlign: "justify"}}>
                <Typography component={'span'}>
                  {" "}
                  {props.metric.description}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions className={globalStyles.cardActions}>
          <div className={styles.actionButtons}>
            {props?.metric?.owner?.email === userInfo?.email ? 
            <>
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setOpenAddMetricValue(true);
                }}
              >
                <AddBoxIcon className={globalStyles.personAddAltOutlinedIcon} />
                </IconButton>
            </>
            :""}
            <IconButton aria-label="share"  onClick={handleSocialShareOpen}>
                <ShareIcon />
              </IconButton>
            <Link to={"/home/graphs/" + props.metric.id}>
              <IconButton aria-label="share">
                <QueryStatsIcon />
              </IconButton>
            </Link>
          </div>
        </CardActions>
      </Card>
      {ShareDiolougeOpen ? (
        <SocialSharing open={ShareDiolougeOpen} metricData = {props}  closeDialog={closePoP}/>
      ) : (
        ""
      )}
      <MetricAddValueDialogForm
        key={props.metric.id}
        show={openAddMetricValue}
        metric={props.metric}
        department={props.department}
        onClose={() => {
          setOpenAddMetricValue(false);
        }}
        onSave={handleSave}
      />
    </>
  );
}
