import BaseCRUD from "../../../common/baseCRUD";
import http from "../../../common/http-common";

class PushService extends BaseCRUD {
  static registerToken(currentToken: string) {
    throw new Error("Method not implemented.");
  }
  constructor() {
    super("/user-fcm-token");
  }

  async registerToken(token: string) {
    return http.post(this.urlBase, { token: token });
  }
}

export default new PushService();
