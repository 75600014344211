import React, { useEffect } from "react";
import styles from "./departmentHealth.module.css";

type DepartmentProps = {
  color: string;
  value: number;
};

export default function CircularPercentage(props: DepartmentProps) {
  const [showValue, setShowValue] = React.useState(0);

  useEffect(() => {
    var current = 0;

    var stepTime = Math.abs(Math.floor(500 / props.value));

    if (props.value != 0) {
      let timer = setInterval(() => {
        current += 1;
        setShowValue(current);
        if (current >= props.value) {
          clearInterval(timer);
        }
      }, stepTime);
    }
  }, [props.value]);

  return (
    <>
      <div className={styles.valueWrapper}>
        <div className={styles.circleContent}>
          <h5>Health Score</h5>
          <div className={styles.circleValue}>
            {showValue != 0 ? showValue : ""}
          </div>
        </div>
        <svg viewBox="0 0 36 36" className={styles.circularChart}>
          <defs>
            <linearGradient id="gradient" x1="50%" y1="50%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={props.color} />
              <stop offset="100%" stopColor="#5eaefd" />
            </linearGradient>
          </defs>

          <path
            className={styles.circle}
            strokeDasharray={showValue + ", 100"}
            stroke="url(#gradient)"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      </div>
    </>
  );
}
