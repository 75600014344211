import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  Avatar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUpdateAuth, useUserAuth } from '../AuthContext';
import { NotificationPopup } from '../common/NotificationPopup';
import globalStyle from '../global.module.css';
import logoutServices from './logoutServices';

export default function Navbar(props: any) {
  const updateAuth = useUpdateAuth();
  const navigation = useNavigate();
  const { userInfo } = useUserAuth();

  const [openNotificationBell, setopenNotificationBell] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<any>();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfilePage = () => {
    navigation('/home/profile');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const result = await logoutServices.LogoutUser(userInfo?.email);
    if (result) {
      updateAuth(false);
      localStorage.removeItem('token');
      handleClose();
      navigation('/login');
    }
  };

  const stringAvatar = (name: string) => {
    if (!name || name.length === 0) return;
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0] ? name.split(' ')[0][0] : ''}${
        name.split(' ')[1] ? name.split(' ')[1][0] : ''
      }`,
    };
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const openNotification = () => {
    setopenNotificationBell(!openNotificationBell);
  };

  return (
    <div>
      <Toolbar>
        <Link to="/home/my-department">
          <div>
            <img
              className={globalStyle.logoNavBar}
              src="/boc-orange.png"
              alt="boc logo"
            />
          </div>
        </Link>

        <Typography sx={{ flexGrow: 1 }}>v0.4.2</Typography>

        <div>
          <IconButton
            onClick={() => openNotification()}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="primary"
          >
            {props?.count > 0 ? (
              <Badge badgeContent={props?.count} color="error">
                <NotificationsNoneIcon />
              </Badge>
            ) : (
              <NotificationsNoneIcon />
            )}
          </IconButton>
          {openNotificationBell ? <NotificationPopup /> : null}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar {...stringAvatar(userInfo?.name || 'BH T')} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfilePage}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </div>
  );
}
