import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useState } from "react";
import { hasStrongTag } from "../../common/util/hasHTMLTag";
import { useToast } from "./ToastProvider";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Toast() {
  const { show } = useToast();
  const [open, setOpen] = useState<boolean>(false);
  React.useEffect(() => {
    if (show && show.message !== "") {
      setOpen(true);
    }
  }, [show]);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={show.duration ? show.duration : 6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: show.vertical ? show.vertical : "top",
        horizontal: show.horizontal ? show.horizontal : "center",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={show.severity}
        sx={{ width: "100%" }}
      >
        {hasStrongTag(show.message) ? <div dangerouslySetInnerHTML={{ __html: show?.message }} /> : show.message} 
      </Alert>
    </Snackbar>
  );
}
