import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUpdateAuth } from "../../AuthContext";
import ErrorMessage from "../../common/ErrorMessage";
import { capitalizeFirstLetter } from "../../common/convertUppercaseFirstLetter";
import { BaseURL } from "../../common/util/baseURL";
import globalStyle from "../../global.module.css";
import { CustomTextField } from "../../shared/CustomTextField";
import { PrimaryButton } from "../../shared/PrimaryButton";
import style from "./formLogin.module.css";

function FormLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const updateAuth = useUpdateAuth();
  const navigate = useNavigate();

  const submitLogin = async (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await axios.post(`${BaseURL}/auth/login`, {
        username: email,
        password: password,
      });

      if (response.data.idToken) {
        localStorage.setItem("token", response.data.idToken.jwtToken);
        updateAuth(true);

        setTimeout(() => {
          window.location.href = "/home/my-department";
        }, 1000);
      } else {
        setError(response.data.message);
      }
    } catch (error: any) {
      if (error.response.data.message == "User is not confirmed.") {
        navigate("/verificationCode?email=" + email);
      }
      if(error.response.data.message.length > 1 && Array.isArray(error.response.data.message)){
        setError(capitalizeFirstLetter(error.response.data.message[0]) + " " + capitalizeFirstLetter(error.response.data.message[1]));
      }else if(Array.isArray(error.response.data.message)){
        setError(capitalizeFirstLetter(error.response.data.message[0]));
      }else{
        setError(capitalizeFirstLetter(error.response.data.message));
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <div>
        <img
          className={globalStyle.logo}
          src="/boc-orange.png"
          alt="boc logo"
        />
        <h1>Sign into Your Account</h1>
      </div>
      <form onSubmit={submitLogin}>
        {error ? <ErrorMessage message={error==="Password should not be empty Username should not be empty"?"Username and password should not be empty":error} /> : null}
        <div className={globalStyle.spaceformItems}>
          <CustomTextField
            id="email-address"
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className={globalStyle.spaceformItems}>
          <CustomTextField
            id="password"
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className={globalStyle.spaceformItems}>
          <Link className={style.link} to={"/forgotPassword"}>
            <h5> Forgot your password? </h5>
          </Link>
        </div>

        <div className={globalStyle.spaceformItems}>
          <PrimaryButton type="submit"> Sign in</PrimaryButton>
        </div>
      </form>
    </div>
  );
}

export default FormLogin;
