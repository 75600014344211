import { Grid, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorFormMsg } from '../../common/ErrorMessage';
import { useToast } from '../../layout/toast/ToastProvider';
import CustomDialog from '../../shared/CustomDialog';
import { CustomTextField } from '../../shared/CustomTextField';
import { OutlinedButton } from '../../shared/OutlinedButton';
import { PrimaryButton } from '../../shared/PrimaryButton';
import TemplateGroupsSelect from './TemplateGroupsSelect';
import { TemplateGroup } from './models/models';
import templateService from './services/template.service';

type Props = {
  isOpen: boolean;
  metric: any;
  onClose: () => void;
  onTemplateDataChange: (data: []) => void;
};
export default function TemplateSaveDialog(props: Props) {
  const [groupselct, setgroupselct] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
  } = useForm();

  const { toastShow } = useToast();
  useEffect(() => {
    setValue('isGlobal', 'true');
    setValue(
      'name',
      props?.metric?.name ? props.metric?.name + ' (Template)' : ' '
    );
  }, [props.metric]);

  const addGroups = (groups: TemplateGroup[]) => {
    setValue(
      'groups',
      groups.map((g) => g.id)
    );
  };

  const onSubmit = async (data: any) => {
    data.data = JSON.stringify(props.metric);
    await templateService.createNew(data).then((resp: AxiosResponse) => {
      toastShow({
        message: 'Template ' + data.name + ' saved succesfully !',
        severity: 'success',
        duration: 700,
      });
    });
    setValue('groups', []);
    reset();
    props.onClose();
    props.onTemplateDataChange(data?.data);
  };

  useEffect(() => {
    if (watch('groups')?.length > 0) {
      setgroupselct(true);
    } else {
      setgroupselct(false);
    }
  }, [watch('groups')]);

  const handleOnCloseError = () => {
    props.onClose();
    setError('name', {
      type: 'manual',
      message: '',
    });
    setError('groups', {
      type: 'manual',
      message: '',
    });
  };

  return (
    <>
      <CustomDialog
        open={props.isOpen}
        onClose={handleOnCloseError}
        title="Save Template"
        content={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Template Name
              </Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: 'This field is required' },
                  validate: {
                    notEmpty: (value) =>
                      value.trim() !== '' || 'This field is required',
                  },
                }}
                render={({ field }) => (
                  <div>
                    <CustomTextField
                      label=""
                      type="text"
                      error={Boolean(errors.name)}
                      {...field}
                    />
                  </div>
                )}
              />
              {errors?.name && <ErrorFormMsg message={errors?.name?.message} />}
            </Grid>
            <Grid item xs={15}>
              <Controller
                name="groups"
                control={control}
                rules={{ required: 'Please select at least one group' }}
                render={({ field }) => (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <TemplateGroupsSelect
                      onSelect={function (
                        templatesGroups: TemplateGroup[]
                      ): void {
                        addGroups(templatesGroups);
                      }}
                      isEditable={true}
                      label="Add to group"
                      defaultvalue={
                        Array.isArray(field.value) ? field.value : []
                      }
                      isError={Boolean(errors.groups)}
                    />
                  </div>
                )}
              />
              {groupselct === false && (
                <ErrorFormMsg message={errors?.groups?.message} />
              )}
            </Grid>
          </Grid>
        }
        footer={
          <>
            <OutlinedButton
              onClick={() => {
                props.onClose();
                handleOnCloseError();
                reset();
              }}
            >
              Cancel
            </OutlinedButton>
            <PrimaryButton form="formMetric" onClick={handleSubmit(onSubmit)}>
              Save
            </PrimaryButton>
          </>
        }
      ></CustomDialog>
    </>
  );
}
