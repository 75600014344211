import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomDialog from "../../../shared/CustomDialog";
import { PrimaryButton } from "../../../shared/PrimaryButton";
import { Department } from "../../departments/models/department";
import MetricDialogForm from "../../metrics/components/MetricADialogEdit";
import { MetricA } from "../models/metric";
import MetricsAList from "./MetricsAList";
type Props = {
  open: boolean;
  onClose: () => void;
  refresh: () => void;
  department: Department;
};

export default function MetricAListDialog(props: Props) {
  const [openAddMetric, setOpenAddMetric] = React.useState<boolean>(false);
  const [metrics, setMetrics] = React.useState<any[]>([]);

  useEffect(() => {
    setMetrics(props.department.metrics);
  }, []);

  const handleOpenAddMetric = () => {
    setOpenAddMetric(true);
  };

  const handleCloseAddMetric = () => {
    setOpenAddMetric(false);
    props.refresh();
  };

  return (
    <>
      <CustomDialog
        open={props.open}
        onClose={props.onClose}
        title="Department Metrics"
        content={
          <Grid container>
            <MetricsAList
              department={props.department}
              showConfig={true}
              onChange={() => {
                props.refresh();
              }}
            />
          </Grid>
        }
        footer={
          <>
            <PrimaryButton onClick={handleOpenAddMetric}>
              New Metric
            </PrimaryButton>
          </>
        }
      ></CustomDialog>

      <MetricDialogForm
        department={props.department}
        show={openAddMetric}
        onClose={() => {
          handleCloseAddMetric();
        }}
        onSave={() => {
          handleCloseAddMetric();
        }}
        onDelete={() => {
          handleCloseAddMetric();
        }}
        metric={new MetricA()}
      />
    </>
  );
}
