import {
  Card,
  CardContent,
  Grid
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorFormMsg } from "../../../common/ErrorMessage";
import { commonNumberInputValidation } from '../../../common/util/validatiinUtillss';
import { useToast } from "../../../layout/toast/ToastProvider";
import CustomDialog from "../../../shared/CustomDialog";
import { CustomTextField } from "../../../shared/CustomTextField";
import { OutlinedButton } from "../../../shared/OutlinedButton";
import { PrimaryButton } from "../../../shared/PrimaryButton";
import { Department } from "../../departments/models/department";
import { MetricA } from "../models/metric";
import metricsAService from "../services/metricsA.service";

type MetricADialogProps = {
  show: boolean;
  metric: MetricA;
  department: Department;
  onClose: () => void;
  onSave: (metricData: any) => void;
  metricdata?:any;
};

export default function MetricAddValueDialogForm(props: MetricADialogProps) {
  const { toastShow } = useToast();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  
  useEffect(() => {
    updateState();
  }, [props.metric, props.department]);

  React.useEffect(() => {
    register("id");
    register("departmentId");
  }, [register]);

  const updateState = () => {
    if (props.metric.id) {
      setValue("id", props.metric.id);
      setValue("name", props.metric.name);
      setValue("currentValue", props.metric.currentValue);
    }
  };
  const onSubmit = async (data: any) => { 
    if (data.id) {
        const metricData  = await metricsAService.updateMetricCurrentValue(data);
        if(metricData){
          toastShow({
            message: "Metric value added successfully !",
            severity: "success",
          });
          props.onSave(data);
        }else{
          toastShow({
          message: "Error occured in adding metric value !",
          severity: "error",
        });
        }
      }
  };

  return (
    <>
      <CustomDialog
        open={props.show}
        onClose={props.onClose}
        title={"Add Metric Value"}
        content={
          <>
            <form id="formMetric">
              <Card variant="outlined" sx={{ marginTop: 1 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Metric Name"
                          type="text"
                          fullWidth
                          {...field}
                          aria-readonly
                          disabled={true}
                        />
                      )}
                    />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="currentValue"
                        control={control}
                        rules={{
                          ...commonNumberInputValidation,
                          validate: {
                            isNumber: (value) => !isNaN(value) || commonNumberInputValidation.isNumber.message,
                          },
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            label="New Value"
                            type="number"
                            {...field}
                            fullWidth
                          />
                        )}
                      />
                      {errors.currentValue && <ErrorFormMsg message={errors.currentValue?.message}/>} 
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </>
        }
        footer={
          <>
            <OutlinedButton
              onClick={() => {
                reset();
                props.onClose();
              }}
            >
              Cancel
            </OutlinedButton>
            <PrimaryButton form="formMetric" onClick={handleSubmit(onSubmit)}>
              Save
            </PrimaryButton>
          </>
        }
      ></CustomDialog>
    </>
  );
}
