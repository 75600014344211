//SHOULD BE "http://localhost:3001" on local environment

export const BaseURL =
  "https://buckhorndevelop1env.eba-7qzrntfh.us-east-1.elasticbeanstalk.com/api";
// export const BaseURL = "http://localhost:3000/api";

export const twitterShareurl = "https://twitter.com/intent/tweet";
export const facebookShareurl = "https://www.facebook.com/sharer/sharer.php";
export const linkedinShareurl =
  "https://www.linkedin.com/sharing/share-offsite/";
export const whatsappShareUrl = "https://wa.me";
