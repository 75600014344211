import { AxiosResponse } from "axios";
import BaseCRUD from "../../../common/baseCRUD";
import http from "../../../common/http-common";
import { Template } from "../models/models";

class TemplatesService extends BaseCRUD {
  constructor() {
    super("/templates");
  }

  //create a new template
  async createNew(template: Template): Promise<AxiosResponse> {
    const resp = await http.post(`${this.urlBase}`, template);
    return resp;
  }

  //update a template
  async update(template: Template): Promise<AxiosResponse> {
    const resp = await http.patch(
      `${this.urlBase}/${template?.id}`,
      template
    );
    return resp;
  }
}

export default new TemplatesService();
