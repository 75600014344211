import { useContext, useEffect, useState } from "react";
import { MessageCount } from "../../../GlobalContext";
import { Department } from "../../departments/models/department";
import departmentsService from "../../departments/services/departments.service";
import InvitationsList from "../../invitations/InvitationsList";
import { UserRolItem } from "./UserRolItem";

type UserRolesListProps = {
  department: Department;
  onChange: () => void;
  canDelete: boolean;
  pathName: string;
};

export default function UserRolesList(props: UserRolesListProps) {
  let sortedRoles;
  const roleOrder: any = { OWNER: 1, COOWNER: 2, VIEWER: 3 };
  const [DepartmentRoles, setDepartmentRoles] = useState<any>([]);
  const { count, setcount }: any = useContext(MessageCount);
  const refresh = async () => {
    if (props?.department) {
      sortedRoles = props?.department?.usersRoles.sort((a: any, b: any) => {
        return roleOrder[a.rol] - roleOrder[b.rol];
      });
      setDepartmentRoles(sortedRoles);
    } else {
      departmentsService
        .getById(props?.department?.id)
        .then((department: any) => {
          if (department) {
            sortedRoles = department?.usersRoles.sort((a: any, b: any) => {
              return roleOrder[a.rol] - roleOrder[b.rol];
            });
            setDepartmentRoles(sortedRoles);
          }
        });
    }
  };
  useEffect(() => {
    if (props?.department?.id) {
      refresh();
    }
    sortedRoles = props?.department?.usersRoles.sort((a, b) => {
      return roleOrder[a.rol] - roleOrder[b.rol];
    });
    setDepartmentRoles(sortedRoles);
  }, [props?.department?.id, count]);

  return (
    <>
      {DepartmentRoles?.map((userRol: any, i: any) => {
        return (
          <UserRolItem
            key={i}
            userRol={userRol}
            updated={() => {
              props.onChange();
            }}
            canDelete={props?.canDelete}
            departmentName={props.department?.name}
          />
        );
      })}
      <InvitationsList
        canDelete={props?.canDelete}
        department={props.department}
        pathName={props.pathName}
      />
    </>
  );
}
