import BaseCRUD from "../../../common/baseCRUD";
import http from "../../../common/http-common";

class SubscrieDepartmentPushService extends BaseCRUD {
  constructor() {
    super("/user-push-subscriptions");
  }

  public isSubscribed(departmentId: string) {
    return http.get(this.urlBase + "/" + departmentId);
  }

  async subscribe(departmentId: string) {
    return http.post(this.urlBase, { departmentId: departmentId });
  }

  async unsubscribe(departmentId: string) {
    return http.delete(this.urlBase + "/" + departmentId);
  }
}

export default new SubscrieDepartmentPushService();
