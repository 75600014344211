import React from "react";
import NotificationsList from "../../../features/notifications/components/NotificationList";

export default class Notifications extends React.Component {
  constructor(props: any) {
    super(props);
  }

  onNotificationClick(): void {}
  render() {
    return <div style={{marginBottom:'80px'}}>
          <NotificationsList />
        </div>
  }
}
