import BaseCRUD from "../../../common/baseCRUD";
import http from "../../../common/http-common";
import { Template } from "../../templates/models/models";
import { MetricA } from "../models/metric";
import { MetricDTO } from "../models/metric-dto";

class MetricsAService extends BaseCRUD {
  constructor() {
    super("/metricsA");
  }

  async getById(id?: any) {
    const resp = await http.get(`${this.urlBase}/${id}`);
    let metric: MetricDTO = resp.data;
    return MetricA.transformFromDTO("", metric);
  }

  async createNew(metricA: MetricA) {
    const resp: any = await http.post(`${this.urlBase}`, metricA);
    if (resp?.response?.status === 400) {
      return resp?.response;
    }
    let metric: MetricDTO = resp.data;
    return MetricA.transformFromDTO("", metric);
  }

  async createFromTemplate(
    template: Template,
    departmentId: string | undefined
  ) {
    let data = JSON.parse(template.data);

    let newMetric = new MetricA();
    newMetric.name = template.name;
    newMetric.description = data.description;
    newMetric.currentValue = data.currentValue;
    newMetric.beforeValue = data.beforeValue;
    newMetric.minThresholdValue = data.minThresholdValue;
    newMetric.maxThresholdValue = data.maxThresholdValue;
    newMetric.goodAtLowerThreshold = data.goodAtLowerThreshold;
    newMetric.penalty = data.penalty;
    newMetric.weighting = data.weighting;
    newMetric.showColor = data.showColor;
    newMetric.redZone = data.redZone;
    newMetric.greenZone = data.greenZone;
    newMetric.departmentId = departmentId;
    newMetric.categories = data.categories;

    const resp = await http.post(`${this.urlBase}`, newMetric);
    let metric: MetricDTO = resp.data;
    return MetricA.transformFromDTO("", metric);
  }

  async update(metric: MetricA) {
    const resp: any = await http.patch(`${this.urlBase}/${metric.id}`, metric);
    if (resp?.response?.status === 400) {
      return resp?.response;
    }
    return resp.data;
  }

  async updateMetricCurrentValue(metric: MetricA) {
    const resp: any = await http.patch(
      `${this.urlBase}/${metric.id}/registers`,
      metric
    );
    if (resp?.response?.status === 400) {
      return resp?.response;
    }
    return resp.data;
  }

  async getAll() {
    const resp = await http.get(`${this.urlBase}`);
    return resp.data.map((metric: MetricDTO) => {
      return MetricA.transformFromDTO("", metric);
    });
  }

  async getResgitersBetweenDates(
    id: string,
    from: string,
    to: string,
    oneRegisterPerDay: boolean
  ) {
    const resp = await http.get(
      `${this.urlBase}/${id}/registers?from=${from}&to=${to}&oneRegisterPerDay=${oneRegisterPerDay}`
    );
    return resp.data;
  }
}

export default new MetricsAService();
