import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateAuth } from "../../AuthContext";
import ErrorMessage from "../../common/ErrorMessage";
import { BaseURL } from "../../common/util/baseURL";
import { emailRegex, nameregex, passwordRegex } from "../../common/util/regex";
import globalStyle from "../../global.module.css";
import { CustomTextField } from "../../shared/CustomTextField";
import { PrimaryButton } from "../../shared/PrimaryButton";
import style from "../signUp/signUp.module.css";

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();
  const updateAuth = useUpdateAuth();
  const search = useLocation().search;
  const inviteId = new URLSearchParams(search).get("invite");

  const submitSignUp = async (event: any) => {
    event.preventDefault();
    if(!nameregex.test(name)){
      setError("Please enter a valid name");
      return false;
    }else if(!emailRegex.test(email)){
      setError("Please enter a valid email");
      return false;
    }else if(!passwordRegex.test(password)){
      setError("The password doesn't meet the policy due to insufficient length.");
      return false;
    }
    try {
       await axios.post(`${BaseURL}/auth/register`, {
        username: name,
        email: email,
        password: password,
        inviteId: inviteId ? parseInt(inviteId) : null,
      });
      setOpenToast(true);
      submitLogin(event);
    } catch (error: any) {
      setError(error.response.data.message);
    }
    setLoading(false);
  }

  const submitLogin = async (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await axios.post(`${BaseURL}/auth/login`, {
        username: email,
        password: password,
      });

      if (response.data.idToken) {
        localStorage.setItem("token", response.data.idToken.jwtToken);
        updateAuth(true);
        setTimeout(() => {
          window.location.href = "/home/my-department";
        }, 1000);
      } else {
        setError(response.data.message);
      }
    } catch (error: any) {
      setError(error.response.data.message);
      if (error.response.data.message == "User is not confirmed.") {
        navigate("/verificationCode?email=" + email + "&method=link");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className={globalStyle.pageContainer}>
        <span className={globalStyle.ellipse2}></span>
        <div>
          <div>
            <img
              className={globalStyle.logo}
              src="/boc-orange.png"
              alt="boc logo"
            />
            <h1>Create A New Account</h1>
          </div>
          <form onSubmit={submitSignUp}>
            {error ? <ErrorMessage message={error} /> : null}

            <div className={globalStyle.spaceformItems}>
              <CustomTextField
                id="fullName"
                label="Full Name"
                type="text"
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                disabled={loading}
              />
            </div>

            <div className={globalStyle.spaceformItems}>
              <CustomTextField
                id="email-address"
                label="Email"
                name="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </div>

            <div className={globalStyle.spaceformItems}>
              <CustomTextField
                id="password"
                label="Password"
                type="password"
                name="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </div>
            <p className={globalStyle.spaceformItems}>
              Password must have{" "}
              <span className={style.greenSpan}>10 characters</span>,{" "}
              <span className={style.greenSpan}>numbers</span>,{" "}
              <span className={style.redSpan}>uppercase</span>,{" "}
              <span className={style.redSpan}>lowercase</span> &{" "}
              <span className={style.redSpan}>special characters</span>
            </p>
            <div className={globalStyle.spaceformItems}>
              <PrimaryButton type="submit" disabled={loading}>
                Sign Up
              </PrimaryButton>
            </div>
            <div className={globalStyle.spaceformItems} style={{display:"flex"}}>
              <h4>Already have an account?</h4>
              <div className={style.link} style={{color:"#3461fd", marginLeft:"5px", marginTop:'4px',}}>
                <Link to={"/login"} >Log In</Link>
              </div>
            </div>
          </form>
        </div>
        <span className={globalStyle.ellipse3}></span>
        <span className={globalStyle.ellipse4}></span>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        autoHideDuration={6000}
      >
        <Alert severity={"success"} sx={{ width: "100%" }}>
          'Successfully Registered'
        </Alert>
      </Snackbar>
    </>
  );
}

export default SignUp;
