"use client";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useLoader } from "./LoaderProvider";
import styles from "./loader.module.css";

export default function Loader({ }) {
  const { isLoading } = useLoader();
  useEffect(() => {
  }, [isLoading]);

  return (
    <div>
      {typeof window === "object" &&
        createPortal(
          <div>
            <div
              className={`fixed z-[100] h-screen w-full inset-0 bg-black   p-3 ease-in-out duration-500 ${isLoading ? "opacity-80 " : "opacity-0 pointer-events-none"
                }`}
            >
              <div className={styles.loader}></div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}
