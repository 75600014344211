import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth, useUserAuth } from "../../AuthContext";
import { MessageCount } from "../../GlobalContext";
import notificationService from "../../features/notifications/services/notification.service";
import pushService from "../../features/push-notifications/services/push.service";
import NavBar from "../../layout/NavBar";
import NavBottom from "../../layout/NavBottom";
import { useToast } from "../../layout/toast/ToastProvider";

const firebaseConfig = {
  apiKey: "AIzaSyB4iCBTDi4UFeqNtwSYKwCRpHq__Wqw2qY",
  authDomain: "boc-wordle.firebaseapp.com",
  projectId: "boc-wordle",
  storageBucket: "boc-wordle.appspot.com",
  messagingSenderId: "486102740064",
  appId: "1:486102740064:web:0960237c482e7cb613a705",
  measurementId: "G-1S0LQ88RQH",
};

export default function Home() {
  const auth = useAuth();
  const { userInfo } = useUserAuth();
  const { toastShow } = useToast();
  const { count, setcount }: any = useContext(MessageCount);

  useEffect(() => {
    if (auth && userInfo) {
      initializeApp(firebaseConfig);
      getFCMToken();
      const messaging = getMessaging();
      onMessage(messaging, (payload: any) => {
        toastShow({ message: payload.notification.body, severity: "info" });
        msgCount();
      });
      msgCount();
    }
  }, [auth, userInfo]);
  const getFCMToken = async () => {
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey:
        "BNPKPih3RFlXseqiwJETF0Y9GFNh5iBX2u0dyTS2X4wNMcowWYXIusVKMPQ297QDQ6bO76yjmxG23SVCVY4WQRA",
    })
      .then((currentToken) => {
        if (currentToken) {
          pushService.registerToken(currentToken);
        }
      })
      .catch((err) => {
        requestPermissionForNotifications();
        console.log("An error occurred while retrieving a token. ", err);
      });
  };
  const requestPermissionForNotifications = async () => {
    await Notification.requestPermission().then((permission: any) => {
      if (permission === "granted") {
        getFCMToken();
      }
    });
  };

  const msgCount = async () => {
    let noti = await notificationService.getMy();
    setcount(noti.length);
  };

  return (
    <>
      <NavBar count={count} />

      <Outlet />
      <NavBottom />
    </>
  );
}
