import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton
} from "@mui/material";
import React from "react";
import SocialSharing from "../../../common/SocialSharing";
import { convertISOStringToMonthDay } from "../../../common/util/date";
import globalStyles from "../../../global.module.css";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import { useToast } from "../../../layout/toast/ToastProvider";
import styles from "../../departments/components/departmentCard.module.css";
import { Department } from "../../departments/models/department";

type DeptInfoProps = {
  department?: Department;
};

export default function MyProfileDepartMentCard(props: DeptInfoProps) {
  const [ShareDiolougeOpen, setShareDiolougeOpen] = React.useState(false);
  const [openUsersRoles, setOpenUsersRoles] = React.useState<boolean>(false);
  const [pushSubscribed, setPushSubscribed] = React.useState<boolean>(false);
   //open close popup boxes
   function handleSocialShareOpen() {
    setShareDiolougeOpen(true);
  }
  const closePoP = () => {
    setShareDiolougeOpen(false);
  };
  const setOpenEditDepartment = () => {
    
  }

  const { setLoading } = useLoader();
  const { toastShow } = useToast();

  //push notification functinality
  const subscribeToNotifications = async () => {
    if (Notification.permission !== "granted") {
      toastShow({
        message: "Please grant permission for browser notifications first, then click 'Subscribe' to receive push notifications.",
        severity: "info",
      });
    }
    else{
      // if (!props.departmentId) 
      // return;
      // setLoading(true);
      // await SubscrieDepartmentPushService.subscribe(props.departmentId);
      // await checkSubscription();
      toastShow({
        message: "Subscribed to push notifications",
        severity: "success",
      });
      setLoading(false);
    }
    };
  
    const unsubscribeToNotifications = async () => {
      // if (!props.departmentId) 
      // return;
      // setLoading(true);
      // await SubscrieDepartmentPushService.unsubscribe(props.departmentId);
      // await checkSubscription();
      toastShow({
        message: "Unsubscribed to push notifications",
        severity: "info",
      });
      setLoading(false);
    };

  return (
    <>
      <Card sx={{ maxWidth: 480, ml:4 , mt:2}} >
        <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <h4 
             style={{fontWeight:"500"}}
            className={styles.departmentName}
            >{props?.department?.name}</h4>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setOpenUsersRoles(true)}>
              <PersonAddAltOutlinedIcon
                className={globalStyles.personAddAltOutlinedIcon}
              />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
          <IconButton onClick={() => setOpenUsersRoles(true)}>
            <EditOutlinedIcon className={globalStyles.EditOutlinedIcon} />
            </IconButton>
            </Grid>
            <Grid item xs={1}>
            {pushSubscribed ? (
              <IconButton onClick={() => unsubscribeToNotifications()}>
                <NotificationsActiveIcon
                  className={globalStyles.EditOutlinedIcon}
                />
              </IconButton>
            ) : (
              <IconButton onClick={() => subscribeToNotifications()}>
                <NotificationsOffIcon
                  className={globalStyles.EditOutlinedIcon}
                />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <p 
            className={styles.para}
            >
              {convertISOStringToMonthDay(
                new Date().toISOString().slice(0, -3)
              )}
            </p>
          </Grid>
        </Grid>
        </CardContent>
          <Box m={0} p={0} mb={1}>
            <Button sx={{fontSize:'12px', fontWeight: 'bold'}}
            >View</Button>
            <Button color="error"  sx={{fontSize:'12px', fontWeight: 'bold'}}
            >
              Delete
            </Button>
        </Box>
      </Card>
      {ShareDiolougeOpen ? (
        <SocialSharing open={ShareDiolougeOpen} metricData = {props}  closeDialog={closePoP}/>
      ) : (
        ""
      )}
    </>
  );
}
