import { MetricA } from "../models/metric";

import styles from "./metrics.module.css";
type MetricAValueProps = {
  metric: MetricA;
};

export default function MetricAValue(props: MetricAValueProps) {
  const metricScore = props.metric.getMetricDisplayScore(props.metric.currentValue, props.metric.maxThresholdValue, props.metric.minThresholdValue)
  return (
    <>
      <div className={styles.valueWrapper}>
        <div
          style={{
            padding: 8,
            borderRadius: 50,
            width: "fit-content",
            minWidth: 38,
            height: 38,
            fontWeight: 600,
            color: props.metric.getValueColor(),
            lineHeight: 1,
            borderWidth: 2,
            borderColor: props.metric.getValueColor(),
          }}
        >
          {metricScore}
        </div>
      </div>
    </>
  );
}
