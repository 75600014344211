import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DepartmentForm from "../../../features/departments/components/DepartmentForm";
import { Department } from "../../../features/departments/models/department";

export default function EditDepartment() {
  const [department, setDepartment] = React.useState<Department>(
    new Department()
  );
  const [allDepartments, setAllDepartments] = React.useState<Department[]>([]);
  let { departmentId } = useParams();

  useEffect(() => {
    console.log(department);
  }, [departmentId]);

  return (
    <Container maxWidth="sm">
      <DepartmentForm
        onSave={(department: any) => {}}
        allDepartments={allDepartments}
        department={department}
        onClose={function (): void {}}
        onDelete={function (): void {}}
      />
    </Container>
  );
}
