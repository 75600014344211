
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import React from "react";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  XIcon
} from "react-share";

const BootstrapDialog = styled(Dialog)(({ theme }:any) => ({
}));

function SocialSharingModel({
    open,
    metricData,
    closeDialog
  }: {
    open: any;
    metricData:any,
    closeDialog:any
  }) {
    const [opens, setOpen] = React.useState(open);
    const handleClose = () => {
      setOpen(false);
    };

    const handleCloses = () => {
      closeDialog(false);
      handleClose()
    };
  
  //social sharing functionality
  const shareUrl = encodeURIComponent("https://development.buckhorn.toronto.bandofcoders.com/home/my-department");
  const shareTitle = encodeURIComponent(`Band Of Coders - ${metricData?.metric?.name || ''}`);

  const handleTwittterShareClick = () => {
     const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`;
     window.open(twitterUrl, '_blank', 'width=600,height=300');
  };
  const handleFacebookShareClick = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareTitle}`;
    window.open(facebookUrl, '_blank', 'width=600,height=400');
  };
  const handleLinkedInShareClick = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&text=${shareTitle}`;
    window.open(linkedInUrl, '_blank', 'width=600,height=400');
  };
  const handleWhatsAppShareClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${shareTitle} ${shareUrl}`;
    window.open(whatsappUrl, '_blank', 'width=600,height=400');
  };

return (
    <BootstrapDialog
    onClose={closeDialog}
    aria-labelledby="customized-dialog-title"
    open={opens}
  >
    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      Share Metric
    </DialogTitle>
    <IconButton
      aria-label="close"
      onClick={handleCloses}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <Stack direction="row" spacing={5} sx={{marginLeft:'16px', marginRight:"16px",marginBottom:"20px"}}>
        <Avatar sx={{background:'#55ACEE',cursor:'pointer'}}>
        <XIcon size={40} round={true}  onClick={handleTwittterShareClick}/>
        </Avatar>
        <Avatar sx={{background:'#3B5998',cursor:'pointer'}} >
          <FacebookIcon size={40} round={true} onClick={handleFacebookShareClick}/>
        </Avatar>
        <Avatar sx={{background:'#007bb5',cursor:'pointer'}}>
          <LinkedinIcon size={40}  round={true} onClick={handleLinkedInShareClick}/>
        </Avatar>
        <Avatar  sx={{background:'#00b489',cursor:'pointer'}}>
          <WhatsappIcon size={40} round={true}  onClick={handleWhatsAppShareClick} />
        </Avatar>
    </Stack>
  </BootstrapDialog>
  )
}

export default SocialSharingModel