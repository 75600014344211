import AddBoxIcon from "@mui/icons-material/AddBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { CardContent, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUserAuth } from "../../../AuthContext";
import SocialSharingModel from "../../../common/SocialSharing";
import { convertISOStringToMonthDayOnlyDate } from "../../../common/util/date";
import globalStyle from "../../../global.module.css";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import { useToast } from "../../../layout/toast/ToastProvider";
import CustomDialog from "../../../shared/CustomDialog";
import MetricADialogForm from "../../metrics/components/MetricADialogEdit";
import MetricAOneLineInfo from "../../metrics/components/MetricAOneLineInfo";
import { MetricA } from "../../metrics/models/metric";
import MetricsAService from "../../metrics/services/metricsA.service";
import SubscrieDepartmentPushService from "../../push-notifications/services/subscribe-department.service";
import TemplateSelectorDialog from "../../templates/TemplateSelectDialog";
import { Template } from "../../templates/models/models";
import UsersRolesDialog from "../../users/components/UsersRolesDialog";
import { Department } from "../models/department";
import departmentsService from "../services/departments.service";
import { DepartmentEditPermission } from "./DepartmentEditPermissions";
import DepartmentForm from "./DepartmentForm";
import CircularPercentage from "./DepartmentHealth";
import { DepartmentViewUserListPermission } from "./ViewDepartmentuserListpermitions";
import styles from "./departmentCard.module.css";

enum MetricList {
  all = "All",
  top = "Top",
}

type DepartmentProps = {
  departmentId: string | undefined;
  onChange: () => void;
  pathName: string;
};

export default function DepartmentCard(props: DepartmentProps) {
  const location = useLocation();
  const { setLoading } = useLoader();
  const { toastShow } = useToast();
  const [openAddMetric, setOpenAddMetric] = React.useState<boolean>(false);
  const [openUsersRoles, setOpenUsersRoles] = React.useState<boolean>(false);
  const { userInfo } = useUserAuth();
  const [Share, canShare] = React.useState<boolean>(true);
  const [canEdit, setcanEdit] = React.useState<boolean>(true);
  const [openEditDepartment, setOpenEditDepartment] =
    React.useState<boolean>(false);
  const [metricListSelected, setMetricListSelected] =
    React.useState<MetricList>(MetricList.all);
  const [department, setDepartment] = React.useState<Department | undefined>(
    undefined
  );
  const [ShareDiolougeOpen, setShareDiolougeOpen] = React.useState(false);
  function handleSocialShareOpen() {
    setShareDiolougeOpen(true);
  }
  const closePoP = () => {
    setShareDiolougeOpen(false);
  };
  const [openTemplateSelector, setOpenTemplateSelector] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSubMenuNew, setOpenSubMenuNew] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSubMenuNew(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubMenuNew(false);
  };
  const [allDepartments, setAllDepartments] = React.useState<Department[]>([]);

  const [pushSubscribed, setPushSubscribed] = React.useState<boolean>(false);

  const refresh = async () => {
    setLoading(true);
    setDepartment(undefined);
    if (!props.departmentId) {
      setLoading(false);
      return;
    }
    checkSubscription();
    departmentsService.getById(props.departmentId).then((department: any) => {
      if (department) {
        setDepartment(department);
        if (userInfo?.departmentsRoles && props.departmentId) {
          const foundRole = userInfo?.departmentsRoles?.find(
            (role: { id: string | undefined }) =>
              role?.id === props?.departmentId
          );
          if (foundRole?.rol === "VIEWER") {
            setcanEdit(false);
          } else {
            setcanEdit(true);
          }
        }
        setLoading(false);
      }
    });
  };

  const checkSubscription = async () => {
    if (!props.departmentId) return;
    let sub = await SubscrieDepartmentPushService.isSubscribed(
      props.departmentId
    );
    sub.data.id ? setPushSubscribed(true) : setPushSubscribed(false);
  };

  useEffect(() => {
    if (!props.departmentId) return;
    refresh();
  }, [props.departmentId, location?.state]);

  const handleSelectAll = () => {
    setMetricListSelected(MetricList.all);
  };

  const handleSelectTop = () => {
    setMetricListSelected(MetricList.top);
  };

  const handleCloseEditValue = () => {
    refresh();
  };

  //push notification functinality
  const subscribeToNotifications = async () => {
    if (Notification.permission !== "granted") {
      toastShow({
        message:
          "Please grant permission for browser notifications first, then click 'Subscribe' to receive push notifications.",
        severity: "info",
      });
    } else {
      if (!props.departmentId) return;
      setLoading(true);
      await SubscrieDepartmentPushService.subscribe(props.departmentId);
      await checkSubscription();
      toastShow({
        message: "Subscribed to push notifications",
        severity: "success",
      });
      setLoading(false);
    }
  };

  const unsubscribeToNotifications = async () => {
    if (!props.departmentId) return;
    setLoading(true);
    await SubscrieDepartmentPushService.unsubscribe(props.departmentId);
    await checkSubscription();
    toastShow({
      message: "Unsubscribed to push notifications",
      severity: "info",
    });
    setLoading(false);
  };

  return department !== undefined ? (
    <>
      <CardContent sx={{ mb: "60px" }}>
        <Grid container>
          <Grid item xs={8}>
            <h1 className={styles.departmentName}>{department.name}</h1>
          </Grid>
          <Grid item xs={1}>
            {pushSubscribed ? (
              <IconButton onClick={() => unsubscribeToNotifications()}>
                <NotificationsActiveIcon
                  className={globalStyle.EditOutlinedIcon}
                />
              </IconButton>
            ) : (
              <IconButton onClick={() => subscribeToNotifications()}>
                <NotificationsOffIcon
                  className={globalStyle.EditOutlinedIcon}
                />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="share" onClick={handleSocialShareOpen}>
              <ShareIcon />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <DepartmentEditPermission department={department}>
              <IconButton onClick={() => setOpenEditDepartment(true)}>
                <EditOutlinedIcon className={globalStyle.EditOutlinedIcon} />
              </IconButton>
            </DepartmentEditPermission>
          </Grid>

          <Grid item xs={1}>
            <DepartmentViewUserListPermission department={department}>
              <IconButton onClick={() => setOpenUsersRoles(true)}>
                <PersonAddAltOutlinedIcon
                  className={globalStyle.personAddAltOutlinedIcon}
                />
              </IconButton>
            </DepartmentViewUserListPermission>
          </Grid>

          <Grid item xs={12}>
            <p className={styles.para}>
              {convertISOStringToMonthDayOnlyDate(
                new Date().toISOString().slice(0, -3)
              )}
            </p>
          </Grid>
        </Grid>
        <div className={styles.container}>
          <CircularPercentage
            color={department.getScoreColor()}
            value={department.getHelathScore()}
          />
        </div>

        <Grid container alignItems="center">
          <Grid item xs={3}>
            <button
              className={`${
                metricListSelected !== MetricList.all
                  ? styles.filterOutlinedButton
                  : styles.filterTextButton
              } ${styles.filterButton}`}
              onClick={handleSelectTop}
            >
              Todays Top 3
            </button>
          </Grid>
          <Grid item xs={3}>
            <button
              className={`${
                metricListSelected == MetricList.all
                  ? styles.filterOutlinedButton
                  : styles.filterTextButton
              } ${styles.filterButton}`}
              onClick={handleSelectAll}
            >
              All
            </button>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={(ev) => {
                handleClick(ev);
              }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <AddBoxIcon className={globalStyle.personAddAltOutlinedIcon} />
            </IconButton>
          </Grid>
        </Grid>
        {metricListSelected === MetricList.all ? (
          <>
            {department?.metrics?.map((metric: MetricA, i) => (
              <div key={i} className={globalStyle.spaceItems}>
                <MetricAOneLineInfo
                  department={department}
                  key={i}
                  metric={metric}
                  onEditSave={() => {
                    handleCloseEditValue();
                  }}
                  onEditDelete={() => {
                    handleCloseEditValue();
                  }}
                  canShare={Share}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {department?.metrics.slice(0, 3).map((metric: MetricA, i) => (
              <div className={globalStyle.spaceItems} key={metric.id}>
                <MetricAOneLineInfo
                  department={department}
                  key={metric.id}
                  metric={metric}
                  onEditSave={() => {
                    handleCloseEditValue();
                    refresh();
                  }}
                  onEditDelete={() => {
                    handleCloseEditValue();
                    refresh();
                  }}
                  canShare={Share}
                />
              </div>
            ))}
          </>
        )}
      </CardContent>

      <CustomDialog
        open={openEditDepartment}
        onClose={() => {
          setOpenEditDepartment(false);
          refresh();
        }}
        title="Edit Department"
        content={
          <DepartmentForm
            allDepartments={allDepartments}
            department={department}
            onSave={() => {
              setOpenEditDepartment(false);
              refresh();
            }}
            onClose={function (): void {
              setOpenEditDepartment(false);
              refresh();
            }}
            onDelete={() => {
              props.onChange();
            }}
          />
        }
        footer={<></>}
      ></CustomDialog>

      <MetricADialogForm
        show={openAddMetric}
        department={department}
        onClose={() => {
          setOpenAddMetric(false);
          localStorage.setItem("MatricData", "");
        }}
        onSave={() => {
          setOpenAddMetric(false);
          refresh();
        }}
        onDelete={() => {
          props.onChange();
        }}
        metric={new MetricA()}
      />

      <UsersRolesDialog
        open={openUsersRoles}
        department={department}
        pathName={props.pathName}
        onChange={function (): void {
          refresh();
        }}
        onClose={function (): void {
          setOpenUsersRoles(false);
        }}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSubMenuNew}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenTemplateSelector(true);
            handleClose();
          }}
        >
          Use template
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenAddMetric(true);
            handleClose();
          }}
        >
          Create new
        </MenuItem>
      </Menu>

      {openTemplateSelector ? (
        <TemplateSelectorDialog
          isOpen={openTemplateSelector}
          onClose={() => {
            setOpenTemplateSelector(false);
          }}
          onSelect={async (templates: Template[]) => {
            setLoading(true);
            for await (const t of templates) {
              await MetricsAService.createFromTemplate(t, department.id);
            }
            await refresh();
            setLoading(false);
          }}
          refresh={() => {}}
          canEdit={canEdit}
        />
      ) : null}
      {ShareDiolougeOpen ? (
        <SocialSharingModel
          open={ShareDiolougeOpen}
          metricData={props}
          closeDialog={closePoP}
        />
      ) : (
        ""
      )}
    </>
  ) : (
    setLoading(department === undefined ? false : true)
  );
}
