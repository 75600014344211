import { convertISOStringToMonthDay } from "../../../common/util/date";
import { Rol, User } from "../../users/models/user";
import { FieldDTO, MetricDTO } from "./metric-dto";

enum MetricFieldType {
  STRING = "string",
  NUMBER = "number",
  SELECTABLE = "selectable",
}

export enum ValueColors {
  green = "#00bc9b",
  yellow = "#dbdd55",
  red = "#dd556e",
}

export interface IRegister {
  id?: string;
  value: any;
  createdAt: string;
}

export interface IOptions {
  id?: string;
  value: any;
  label: string;
}

export interface IMetricField {
  id?: number;
  description: string;
  actualRegister: IRegister;
  options?: IOptions[];
  label: string;
}

export class MetricField implements IMetricField {
  id?: number;
  description: string = "";
  actualRegister: IRegister;
  options?: IOptions[] = [];

  constructor(public label: string, public type: MetricFieldType) {
    this.actualRegister = {
      value: "",
      createdAt: "",
    };
  }
}

export interface IMetric {
  id?: string;
  name: string;
  description: string;
  owner: User;
  categories: Array<string>;
  updateDateTime: string;
  departmentId?: string;

  getWeightedScore(metricsLength: number): number;
}

export class Metric implements IMetric {
  id?: string;
  name: string = "";
  description: string = "";
  categories: string[] = [];
  departmentId?: string = "";
  public owner: User = new User();
  public currentValue = 99;
  updateDateTime: string = "";

  public getWeightedScore(metricsLength: number): number {
    return 9999999999;
  }
}

export class MetricA extends Metric {
  public id?: string;
  public departmentId?: any;
  public name: string = "";
  public description: string = "";
  public categories: string[] = [];
  public currentValue = 0;
  public beforeValue = 0;
  public minThresholdValue = 0;
  public maxThresholdValue = 0;
  public goodAtLowerThreshold = "false";
  public penalty = 0;
  public weighting = 1;
  public showColor: boolean = false;
  public redZone = 0;
  public greenZone = 0;

  static transformFromDTO(departmentId = "", data: MetricDTO): MetricA {

    let metricA: MetricA = new MetricA();
    metricA.id = data?.id;
    metricA.name = data?.name;
    metricA.departmentId = departmentId;
    metricA.updateDateTime = data?.updateDateTime;
    metricA.description = data?.description;

    if (data?.owner) metricA.owner.email = data?.owner?.email;

    if (data?.department && data?.department?.usersRoles) {
      //TODO #103
      let owner = data.department.usersRoles.find(
        (user: any) => user.rol === Rol.OWNER
      );
      if (owner) metricA.owner.email = owner?.user.email;
    }

    if (data?.fields) {
      data.fields.forEach((field: FieldDTO) => {
        if (field.registers && field.registers[0]) {
          field.registers = this.orderByDate(field.registers);

          let value: any = field.registers[field.registers.length - 1].value;
          value = field.registers[field.registers.length - 1].value;

          (metricA as any)[field.name] = value;

          metricA.updateDateTime = convertISOStringToMonthDay(
            data?.updateDateTime
          );
        }
      });
    }
    return metricA;
  }

  static orderByDate(registers: any) {
    return registers.sort((a: any, b: any) =>
      a.createDateTime > b.createDateTime
        ? 1
        : b.createDateTime > a.createDateTime
        ? -1
        : 0
    );
  }

  public getValueGrow(): number {
    return this.currentValue - this.beforeValue;
  }

  public distributeEvenWeighting(instancesLength: number): number {
    if (instancesLength == 0) return 100;
    return 100 / instancesLength;
  }

  public getWeighting(metricsLength: number): number {
    if (this.weighting == 0) {
      return this.distributeEvenWeighting(metricsLength);
    } else {
      return this.weighting;
    }
  }

  public getWeightedScore(departmentWeight: number) {
      let departmentWeightPercentage = Number(this.weighting)/departmentWeight;
      let metricweightVal = this.getMetricFinalScore(this.currentValue,this.maxThresholdValue,this.minThresholdValue) * departmentWeightPercentage;
      return metricweightVal;
  }

  getValueColor(): string {
    if (this.goodAtLowerThreshold.toString() === "false") {
      if (Number(this.currentValue) >= Number(this.redZone)) {
        return ValueColors.red;
      }
      if (Number(this.currentValue) <= Number(this.greenZone)) {
        return ValueColors.green;
      }
    } else {
      if (Number(this.currentValue) <= Number(this.redZone)) {
        return ValueColors.red;
      }
      if (Number(this.currentValue) >= Number(this.greenZone)) {
        return ValueColors.green;
      }
    }
    return ValueColors.yellow;
  }

  getMetricScoreStep1(actualVal:number, maxThresholdValue:number, minThresholdValue:number) {
    if(minThresholdValue >= 0 && maxThresholdValue > 0){
      return (Math.max( Math.min(actualVal, maxThresholdValue), minThresholdValue) - minThresholdValue) / Number(maxThresholdValue - minThresholdValue);
    }else{
      return 0;
    }
  }

  getMetricFinalScore(actualVal:number, maxThresholdValue:number, minThresholdValue:number) {
    let step1Value = this.getMetricScoreStep1(actualVal, maxThresholdValue, minThresholdValue);
    if(this.goodAtLowerThreshold.toString() === "true"){
      return step1Value;
    } else {
      return step1Value === 0 ? 0 : 1-step1Value;
    }
  }

  getMetricDisplayScore(actualVal:number, maxThresholdValue:number, minThresholdValue:number) {
    let displayMetricScore = this.getMetricFinalScore(actualVal,maxThresholdValue,minThresholdValue);
    return Math.round(displayMetricScore * 100);
  }
}
