import moment from "moment";

export const convertISOStringToMonthDay = (date: string) => {
  const tempDate = new Date(date).toString().split(" ");
  const formattedDate = `${tempDate[1]} ${+tempDate[2]} ${tempDate[4]}`;
  return formattedDate;
};

export const convertISOStringToMonthDayOnlyDate = (date: string) => {
  const tempDate = new Date(date).toString().split(" ");
  const formattedDate = `${tempDate[1]} ${+tempDate[2]}`;
  return formattedDate;
};

export const convertZToEST = (date: string) => {
  const tempDate = new Date(date);
  const formattedDate = tempDate.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  return formattedDate;
};

export const isValidDate = (dateVal: any) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const  givenDate = moment(dateVal).format('YYYY-MM-DD');
  if(givenDate<=currentDate){
   return true
  }else{
    return false;
  }
};