import AddBoxIcon from "@mui/icons-material/AddBox";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShareIcon from "@mui/icons-material/Share";
import { Grid, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SocialSharingModel from "../../../common/SocialSharing";
import globalStyle from "../../../global.module.css";
import CustomDialog from "../../../shared/CustomDialog";
import { DepartmentEditPermission } from "../../departments/components/DepartmentEditPermissions";
import { Department } from "../../departments/models/department";
import { MetricA } from "../models/metric";
import MetricAddValueDialogForm from "./MetricAddValueDialog";
import MetricAValue from "./MetricAValue";
import styles from "./metrics.module.css";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type MetricADialogInfoProps = {
  open: boolean;
  onClose: () => void;
  metric: MetricA;
  department: Department;
};

export default function MetricADialogInfo(props: MetricADialogInfoProps) {
  const navigation = useNavigate();
  const [ShareDiolougeOpen, setShareDiolougeOpen] = React.useState(false);
  const [openAddMetricValue, setOpenAddMetricValue] = React.useState<boolean>(false);
  const [metricData, setmetricData] = React.useState(props.metric);
  const [addValue, setAddValue] = React.useState(false);
  //open close popup boxes
  function handleSocialShareOpen() {
   setShareDiolougeOpen(true);
 }
 const closePoP = () => {
   setShareDiolougeOpen(false);
 };

 const handleSave = (updatedMetricData: any) => {
  metricData.currentValue = updatedMetricData.currentValue;
  setmetricData(metricData)
  setOpenAddMetricValue(false);
  setAddValue(true);
};

const handleClose = () => {
  if(addValue){
    setAddValue(false);
    const url = new URL(window.location.href);
    props.onClose();
    navigation(url.pathname, { state: { key: 'refresh' } });
  }else{
    props.onClose();
  }
};

  return (
    <>
      <CustomDialog
        open={props.open}
        onClose={handleClose}
        title={props.metric.name}
        content={
          <>
            <Grid container>
              <Grid item xs={8}>
                {props.department ? (
                  <Typography
                    sx={{
                      fontSize: 14,
                      paddingTop: 1,
                      textAlign: "left",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {props.department.name}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={4} sx={{display: "grid", placeContent: "flex-end"}}>
                <MetricAValue metric={metricData} />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>Owner</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography sx={{ textAlign: "right" }}>
                  {props.metric.owner.email}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>Min Threshold</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "right" }}>
                  {props.metric.minThresholdValue}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>Max Threshold</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "right" }}>
                  {props.metric.maxThresholdValue}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                  Good at lower threshold
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "right" }}>
                  {props.metric.goodAtLowerThreshold === "true" ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>Last Updated</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: "right" }}>
                  {props.metric.updateDateTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold" }}>Description</Typography>
              </Grid>
              <Grid item xs={12} sx={{paddingTop: 3, paddingRight:0.5,  textAlign: "justify"}}>
                <Typography component={'span'}>
                  {props.metric.description}
                </Typography>
              </Grid>
            </Grid>
          </>
        }
        footer={
          <div className={styles.actionButtons}>
            <DepartmentEditPermission department={props.department}>
            <IconButton
                 onClick={(ev) => {
                  ev.stopPropagation();
                  setOpenAddMetricValue(true);
                }}
              >
                <AddBoxIcon className={globalStyle.personAddAltOutlinedIcon} />
            </IconButton>
            </DepartmentEditPermission>
            <IconButton onClick={handleSocialShareOpen}>
              <ShareIcon />
            </IconButton>
            <Link to={"/home/graphs/" + props.metric.id}>
              <IconButton>
                <QueryStatsIcon />
              </IconButton>
            </Link>
          </div>
        }
      />
      {ShareDiolougeOpen ? (
        <SocialSharingModel open={ShareDiolougeOpen} metricData = {props}  closeDialog={closePoP}/>
      ) : (
        ""
      )}
      <MetricAddValueDialogForm
        key={props.metric.id}
        show={openAddMetricValue}
        metric={props.metric}
        department={props.department}
        onClose={() => {
          setOpenAddMetricValue(false);
        }}
        onSave={handleSave}
      />
    </>
  );
}


