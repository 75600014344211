import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useUserAuth } from "../../AuthContext";
import { emailRegex } from "../../common/util/regex";
import { useToast } from "../../layout/toast/ToastProvider";
import { OutlinedButton } from "../../shared/OutlinedButton";
import { PrimaryButton } from "../../shared/PrimaryButton";
import { Rol } from "../users/models/user";
import { SendInvitation } from "./models/invitation";
import invitationsService from "./services/invitations.service";


type InviteUserDialogProps = {
  show: boolean;
  departmentId: string;
  onClose: () => void;
  onSend: () => void;
};

type FormData = {
  rol: string;
  email: string;
};

export default function InviteUserDialog(props: InviteUserDialogProps) {
  const { userInfo } = useUserAuth();
  const { toastShow } = useToast();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },setError
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      rol:""
    },
  });

  useEffect(() => {
    reset();
  }, [props])


  const [title, setTitle] = React.useState("Send Invite");
  const [allRoles, setAllRoles] = React.useState<any[]>([
    Rol.COOWNER,
    Rol.VIEWER,
  ]);

  const onSubmit = async (data: any) => {
    let invitation: SendInvitation = {
      userReceivingEmail: data.email,
      rol: data.rol,
      departmentId: props.departmentId,
    };
   
    if(userInfo?.email === data.email){
      toastShow({
        message:
          "Owner email and send invite email are same",
        severity: "error",
      });
    }else{
      const res : any = await invitationsService.sendInvitation(invitation);
      if(res?.response?.data?.statusCode === 409){
        toastShow({
          message:
            "An invitation already exists for this email address. Please ask the user to sign in and accept invitation on the notification page.",
          severity: "success",
        });
        return false;
      }
      toastShow({
        message:
          "Invitation sent. Please ask the user to sign in and accept invitation on the notification page.",
        severity: "success",
      });
      reset();
      props.onSend();
    }}
  
  return (
    <>
      <Dialog
        open={props.show}
        onClose={() => {
          props.onClose();
        }}
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ overflowY: "unset"}} >
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid email address',
              },
            }}
            
            render={({ field }) => (
              <TextField
                fullWidth
                label=" User email"
                type="email"
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                sx={{margin:"6px 0 0 0 !important"}}
              />
            )}
          />
          <Controller
            name="rol"
            control={control}
            rules={{
              required: 'Role is required',
            }}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.rol}  margin="normal">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  {...field}
                
                  onChange={(e: SelectChangeEvent<string>) =>
                    field.onChange(e.target.value)
                  }
                  onBlur={field.onBlur}
                >
                  {allRoles.map((d, i) => (
                    <MenuItem key={i} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                </Select>
                {errors.rol && (
              <FormHelperText>{errors.rol.message}</FormHelperText>
            )}
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={props.onClose}>Cancel</OutlinedButton>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>Send</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
