import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useAuth, useUserAuth } from "../../../AuthContext";
import { useToast } from "../../../layout/toast/ToastProvider";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { CustomFormControl } from "../../../shared/CustomFormControl";
import { CustomTextButton } from "../../../shared/CustomTextButton";
import { CustomTextField } from "../../../shared/CustomTextField";
import { OutlinedButton } from "../../../shared/OutlinedButton";
import { PrimaryButton } from "../../../shared/PrimaryButton";
import { Invitation } from "../../invitations/models/invitation";
import MetricAListDialog from "../../metrics/components/MetricAListDialog";
import UsersRolesDialog from "../../users/components/UsersRolesDialog";
import { Department } from "../models/department";
import departmentsService from "../services/departments.service";
import styles from "./departmentCard.module.css";

type DepartmentEditProps = {
  department?: Department;
  onSave: (department: Department) => void;
  allDepartments: Department[];
  onDelete: () => void;
  onClose: () => void;
};

export default function DepartmentForm(props: DepartmentEditProps) {
  const auth = useAuth();
  const { userInfo } = useUserAuth();
  const { toastShow } = useToast();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [openMetricList, setOpenMetricList] = useState<boolean>(false);
  const [openUsersRoles, setOpenUsersRoles] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [parentId, setParentId] = useState<string | undefined>(
    props?.department?.parentId || ""
  );
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props?.department) {
      setValue("name", props.department.name);
      setValue("parentId", props.department.parentId);
      setParentId(props.department?.parentId);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [props.department]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (
        props?.department?.name !== value.name ||
        props?.department?.parentId !== value.parentId
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, props?.department]);

  const refresh = () => {
    if (props?.department) {
      setValue("name", props.department.name);
      setValue("parentId", props.department.parentId);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (!isEditing) {
        await departmentsService.createNew(
          data.name,
          data.parentId ? data.parentId : "0"
        );
        toastShow({
          message: "Department " + data.name + " created successfully!",
          severity: "success",
          duration: 600,
        });
      } else {
        const dept = new Department();
        dept.id = props.department?.id;
        dept.name = data.name;
        dept.parentId = data.parentId ? data.parentId : "0";
        await departmentsService.update(dept);
        toastShow({
          message: "Department " + data.name + " updated successfully!",
          severity: "success",
          duration: 600,
        });
      }
      props.onSave(data);
      reset();
    } catch (error) {
      console.error("Failed to save department", error);
    }
  };

  const delete_ = async () => {
    try {
      await departmentsService.delete(props.department?.id);
      toastShow({
        message:
          "Department " + props.department?.name + " deleted successfully!",
        severity: "info",
      });
      props.onDelete();
      props.onClose();
    } catch (error) {
      console.error("Failed to delete department", error);
    }
  };

  const openConfirmationDialog = () => {
    setOpenDeleteConfirmation(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props?.allDepartments?.length > 0 && (
            <Controller
              name="parentId"
              control={control}
              defaultValue={parentId}
              render={({ field }: any) => (
                <CustomFormControl>
                  <InputLabel id="select-parent-dept" sx={{ color: "#1976d2" }}>
                    Parent Department
                  </InputLabel>
                  <Select
                    labelId="select-parent-dept"
                    id="simple-select"
                    label="Parent Department"
                    {...field}
                  >
                    <MenuItem key={0} value={"0"}>
                      No Parent
                    </MenuItem>
                    {props?.allDepartments?.map((d) =>
                      d.id !== props.department?.id ? (
                        <MenuItem key={d.id} value={d.id}>
                          {d.name}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </CustomFormControl>
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            defaultValue={props?.department?.name}
            rules={{
              required: { value: true, message: "This field is required" },
              validate: {
                notEmpty: (value) =>
                  value.trim() !== "" || "This field is required",
                minLength: (value) =>
                  value.length >= 10 || "Minimum length is 10 characters",
                maxLength: (value) =>
                  value.length <= 30 || "Maximum length is 30 characters",
                noSpecialChars: (value) =>
                  /^[a-zA-Z0-9\s]*$/.test(value) ||
                  "Special characters are not allowed",
              },
            }}
            render={({ field }: any) => (
              <CustomTextField
                className={styles.formControl}
                label="Department Name"
                type="text"
                {...field}
                error={!!errors.name}
                helperText={errors.name && (errors.name as FieldError).message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container spacing={2} mt={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              {isEditing && (
                <OutlinedButton
                  onClick={() => {
                    setOpenMetricList(true);
                  }}
                >
                  Metric List
                </OutlinedButton>
              )}
            </Grid>
            <Grid item xs={6}>
              {isEditing && (
                <OutlinedButton
                  onClick={() => {
                    setOpenUsersRoles(true);
                  }}
                >
                  Users List
                </OutlinedButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </PrimaryButton>
        </Grid>
        <Grid item xs={12}>
          {isEditing && (
            <>
              <CustomTextButton onClick={openConfirmationDialog}>
                <DeleteOutlinedIcon sx={{ fontSize: 16 }} />
                Delete
              </CustomTextButton>
              <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={() => {
                  setOpenDeleteConfirmation(false);
                }}
                message={"Are you sure to delete this department?"}
                onAccept={() => {
                  setOpenDeleteConfirmation(false);
                  delete_();
                }}
              />
            </>
          )}
        </Grid>
      </Grid>

      <MetricAListDialog
        open={openMetricList}
        onClose={() => setOpenMetricList(false)}
        refresh={refresh}
        department={props.department || new Department()}
      />
      <UsersRolesDialog
        open={openUsersRoles}
        department={props.department || new Department()}
        onChange={refresh}
        onClose={() => setOpenUsersRoles(false)}
        pathName=""
      />
    </>
  );
}
