import http from "./http-common";

export default class BaseCRUD {
  urlBase: string = "/";

  constructor(baseURL: string) {
    this.urlBase = baseURL;
  }

  async get(id?: any) {
    const url = id ? `${this.urlBase}/${id}` : `${this.urlBase}`;
    return await http.get(url);
  }

  async post({ id, data }: { id?: any; data?: any }) {
    const url = id ? `${this.urlBase}/${id}` : `${this.urlBase}`;
    return await http.post(url, data ? data : {});
  }

  async update({ id, data }: { id?: any; data?: any }) {
    const url = id ? `${this.urlBase}/${id}` : `${this.urlBase}`;
    return await http.patch(url, data ? data : {});
  }

  async delete(id?: any) {
    const url = id ? `${this.urlBase}/${id}` : `${this.urlBase}`;
    return await http.delete(url);
  }
}
