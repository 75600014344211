import { createContext, useContext, useEffect, useState } from "react";
import UserService from "./features/users/services/users.service";

const AuthContext = createContext(false);
const AuthUpdateContext = createContext((state: boolean) => {});
const UserContext = createContext<any>({});

export function useAuth() {
  return useContext(AuthContext);
}
export function useUpdateAuth() {
  return useContext(AuthUpdateContext);
}

export function useUserAuth() {
  return useContext(UserContext);
}

export function AuthProvider({ children }: any) {
  const [auth, setAuth] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(undefined);

  function toggleAuth() {
    setAuth(!auth);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setAuth(true);
      updateUserInfo();
    } else {
      if (window.location.pathname.includes("/home/notifications/")) {
        window.location.href = "/login";
      }
    }
  }, []);

  const updateUserInfo = () => {
    UserService.getMy()
      .then((res: any) => {
        setUserInfo(res);
      })
      .catch((err: any) => {
        localStorage.removeItem("token");
      });
  };

  const value = {
    userInfo,
    updateUserInfo,
  };

  return (
    <AuthContext.Provider value={auth}>
      <AuthUpdateContext.Provider value={toggleAuth}>
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
}
