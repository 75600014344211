import { Container } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { useState } from "react";
import { Navigate } from "react-router";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { MessageCount } from "./GlobalContext";
import globalStyles from "./global.module.css";
import { LoaderProvider } from "./layout/laoder/LoaderProvider";
import { ToastProvider } from "./layout/toast/ToastProvider";
import ForgotPassword from "./pages/forgotPassword";
import DepartmentsDashboard from "./pages/home/DepartmentsDashboard/DepartmentsDashboard";
import GraphDashboard from "./pages/home/GraphsDashboard/GraphsDashboard";
import Home from "./pages/home/Home";
import MetricsDashboard from "./pages/home/MetricsDashboard/MetricsDashboard";
import MyDepartmentsDashboard from "./pages/home/MyDepartmentDashboard.tsx/MyDepartmentDashboard";
import MyProfile from "./pages/home/MyProfile/myprofile";
import EditDepartment from "./pages/home/editDepartment/EditDepartment";
import Notifications from "./pages/home/notifications/Notifications";
import Login from "./pages/login/Login";
import SignUp from "./pages/signUp/SignUp";
import VerificationCode from "./pages/verificationCode/VerificationCode";

function App() {
  const auth = useAuth();
  const [count, setcount] = useState("");
  return (
    <ToastProvider>
      <LoaderProvider>
        <MessageCount.Provider value={{ count, setcount }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={globalStyles.pageBackground}>
              <Container maxWidth="sm">
                <Router>
                  {auth === true ? (
                    <Routes>
                      <Route
                        path="/login"
                        element={
                          !auth ? (
                            <Login />
                          ) : (
                            <Navigate to="/home/my-department" />
                          )
                        }
                      />
                      <Route
                        path="/"
                        element={
                          !auth ? (
                            <Login />
                          ) : (
                            <Navigate to="/home/my-department" />
                          )
                        }
                      />
                      <Route
                        path="/home"
                        element={!auth ? <Login /> : <Home />}
                      >
                        <Route
                          path="/home/my-department"
                          element={<MyDepartmentsDashboard />}
                        />
                        <Route
                          path="/home/departments"
                          element={<DepartmentsDashboard />}
                        />
                        <Route
                          path="/home/graphs"
                          element={<GraphDashboard />}
                        />
                        <Route
                          path="/home/graphs/:metricId"
                          element={<GraphDashboard />}
                        />
                        <Route
                          path="/home/metrics"
                          element={<MetricsDashboard />}
                        />
                        <Route
                          path="/home/editDepartment/:departmentId"
                          element={<EditDepartment />}
                        />
                        <Route
                          path="/home/editDepartment/:departmentId"
                          element={<EditDepartment />}
                        />

                        <Route
                          path="/home/notifications"
                          element={<Notifications />}
                        />
                        <Route path="/home/profile" element={<MyProfile />} />
                      </Route>
                      <Route
                        path="/signUp"
                        element={
                          !auth ? <SignUp /> : <Navigate to="/dashboard" />
                        }
                      />
                      <Route
                        path="/verificationCode"
                        element={
                          !auth ? (
                            <VerificationCode />
                          ) : (
                            <Navigate to="/dashboard" />
                          )
                        }
                      />
                      <Route
                        path="/forgotPassword"
                        element={
                          !auth ? (
                            <ForgotPassword />
                          ) : (
                            <Navigate to="/dashboard" />
                          )
                        }
                      />
                    </Routes>
                  ) : (
                    <Routes>
                      <Route
                        path="/login"
                        element={
                          !auth ? (
                            <Login />
                          ) : (
                            <Navigate to="/home/my-department" />
                          )
                        }
                      />
                      <Route
                        path="/"
                        element={
                          !auth ? (
                            <Login />
                          ) : (
                            <Navigate to="/home/my-department" />
                          )
                        }
                      />
                      <Route
                        path="/signUp"
                        element={
                          !auth ? <SignUp /> : <Navigate to="/dashboard" />
                        }
                      />
                      <Route
                        path="/verificationCode"
                        element={
                          !auth ? (
                            <VerificationCode />
                          ) : (
                            <Navigate to="/dashboard" />
                          )
                        }
                      />
                      <Route
                        path="/forgotPassword"
                        element={
                          !auth ? (
                            <ForgotPassword />
                          ) : (
                            <Navigate to="/dashboard" />
                          )
                        }
                      />
                    </Routes>
                  )}
                </Router>
              </Container>
            </div>
          </LocalizationProvider>
        </MessageCount.Provider>
      </LoaderProvider>
    </ToastProvider>
  );
}

export default App;
