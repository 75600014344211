import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth, useUserAuth } from "../../../AuthContext";
import DepartmentCard from "../../../features/departments/components/DepartmentCard";
import DepartmentForm from "../../../features/departments/components/DepartmentForm";
import { Department } from "../../../features/departments/models/department";
import departmentsService from "../../../features/departments/services/departments.service";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import CustomDialog from "../../../shared/CustomDialog";

export default function MyDepartmentsDashboard(props: {}) {
  const [department, setDepartment] = useState<Department>();
  const [dept, setdept] = useState<number>(0);
  const [openAddDepartment, setOpenAddDepartment] = useState(false);
  const auth = useAuth();
  const { userInfo } = useUserAuth();
  const { setLoading } = useLoader();
  const [allDepartments, setAllDepartments] = useState<Department[]>([]);

  useEffect(() => {
    refresh();
  }, [userInfo, auth]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departments = await departmentsService.getAll();
        setAllDepartments(departments);
      } catch (error) {
        console.error("Failed to fetch departments", error);
      }
    };

    if (auth && userInfo) {
      fetchDepartments();
    }
  }, [auth, userInfo]);

  const refresh = async () => {
    if (userInfo && auth) getDepartment();
  };

  const getDepartment = async () => {
    setLoading(true);
    let dept = await departmentsService.getDepartmentHome();
    if (dept) {
      setDepartment(dept);
    } else {
      setDepartment(undefined);
      setdept(1);
    }
    setLoading(false);
  };

  return (
    <>
      {department ? (
        <DepartmentCard
          departmentId={department.id}
          onChange={() => {
            refresh();
          }}
          pathName={"my-departments"}
        />
      ) : (
        <>
          {dept === 1 ? (
            <>
              You don't have any department assigned, please create a new one or
              check your notifications.
              <Grid item xs={12}>
                <Link to={"/home/notifications"}>
                  <Button variant="text">Notifications</Button>
                </Link>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenAddDepartment(true);
                  }}
                >
                  New Department
                </Button>
              </Grid>
            </>
          ) : (
            ""
          )}
        </>
      )}
      <CustomDialog
        open={openAddDepartment}
        onClose={() => {
          setOpenAddDepartment(false);
          refresh();
        }}
        title="New Department"
        content={
          <DepartmentForm
            allDepartments={allDepartments}
            onSave={(data: Department) => {
              if (data.id) getDepartment();
              setOpenAddDepartment(false);
              refresh();
            }}
            onClose={function (): void {
              setOpenAddDepartment(false);
              refresh();
            }}
            onDelete={() => {
              refresh();
            }}
          />
        }
        footer={<></>}
      ></CustomDialog>
    </>
  );
}
