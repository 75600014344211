import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { isValidDate } from "../../../common/util/date";
import { Department } from "../../../features/departments/models/department";
import { MetricA } from "../../../features/metrics/models/metric";
import { FieldDTO } from "../../../features/metrics/models/metric-dto";
import metricsAService from "../../../features/metrics/services/metricsA.service";
import { User } from "../../../features/users/models/user";
import usersService from "../../../features/users/services/users.service";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import { useToast } from "../../../layout/toast/ToastProvider";
import { CustomFormControl } from "../../../shared/CustomFormControl";
import { CustomMobileDatePicker } from "../../../shared/CustomMobileDatePicker";

export default function GraphDashboard({}) {
  let { metricId } = useParams();
  const navigate = useNavigate();
  const [Duration, setDuration] = React.useState<any>({
    w: false,
    m: false,
    y: true,
    y_5: false,
  });
  const [from, setFrom] = React.useState<string>(
    moment().add(-365, "days").format()
  );
  const [to, setTo] = React.useState<string>(moment().add(0, "days").format());
  const [oneRegisterPerDay, setOneRegisterPerDay] =
    React.useState<boolean>(false);

  const [department, setDepartment] = React.useState<Department>(
    new Department()
  );

  const [metrics, setMetrics] = React.useState<MetricA[]>([]);
  const [chartDateData, setChartDateData] = React.useState<any[]>([]);
  const [chartLabelData, setChartLabelData] = React.useState<any[]>([]);

  const [actualMetricId, setActualMetricId] = React.useState<string>("");

  const { setLoading } = useLoader();
  const { toastShow } = useToast();

  useEffect(() => {
    setFrom(
      moment()
        .add(
          Duration?.w
            ? -7
            : Duration?.m
            ? -30
            : Duration?.y
            ? -365
            : Duration?.y_5
            ? -1825
            : 0,
          "days"
        )
        .format()
    );
  }, [Duration]);

  useEffect(() => {
    getuserMetrics();
  }, []);

  useEffect(() => {
    if (actualMetricId) convertData();
  }, [actualMetricId, from, to]);

  const getuserMetrics = async () => {
    setLoading(true);
    const user: User = await usersService.getMy();
    let m = await metricsAService.get();
    if (m) setMetrics(m.data);
    if (metricId) setActualMetricId(metricId);
    setLoading(false);
  };

  const convertData = async () => {
    if (!actualMetricId) return;
    let data;
    if (moment(to).format("YYYY-MM-DD") < moment(from).format("YYYY-MM-DD")) {
      toastShow({
        message: "Please select valid date range",
        severity: "error",
      });
    } else {
      data = await metricsAService.getResgitersBetweenDates(
        actualMetricId!,
        moment(from).format("YYYY-MM-DD"),
        moment(to).format("YYYY-MM-DD"),
        oneRegisterPerDay
      );
    }
    const field: FieldDTO = await data.fields.find(
      (field: any) => field.name === "currentValue"
    );
    if (field.registers.length > 0) {
      field.registers = field.registers?.reverse().map((register: any) => {
        return {
          ...register,
          value: parseFloat(register.value),
          updateDateTime: moment(register.updateDateTime).format("MM/DD/YYYY"),
        };
      });
    }
    if (field?.registers?.length > 12) {
      const filter_data: any = processChartData(field.registers?.reverse());

      setChartDateData(field?.registers?.reverse());
      setChartLabelData(filter_data);
    } else {
      setChartDateData(field.registers?.reverse());
      setChartLabelData(field.registers?.reverse());
    }
  };

  const handleMetricChange = (id: string) => {
    navigate(`/home/graphs/${id}`);
    setActualMetricId(id);
  };
  const handleFromChange = (newValue: any | null) => {
    const dateVal = newValue.format();
    const date = isValidDate(moment(dateVal).format("YYYY-MM-DD"));
    if (date) {
      setFrom(newValue.format());
    } else {
      toastShow({
        message: "Invalid date format",
        severity: "error",
      });
      setFrom(moment().format("YYYY-MM-DD"));
    }
  };

  const handleToChange = (newValue: any | null) => {
    const dateVal = newValue.format();
    const date = isValidDate(moment(dateVal).format("YYYY-MM-DD"));
    if (date) {
      setTo(newValue.add(0, "days").format());
    } else {
      toastShow({
        message: "Invalid date format",
        severity: "error",
      });
    }
  };

  function processChartData(data: any[]) {
    const length = data.length;
    const firstIndex = 0;
    const lastIndex = length - 1;
    const middleIndex = Math.floor(length / 2);
    const quarterIndex = Math.floor(length / 4);
    const threeQuarterIndex = Math.floor((3 * length) / 4);

    const filteredData = [
      data[firstIndex],
      data[quarterIndex],
      data[middleIndex],
      data[threeQuarterIndex],
      data[lastIndex],
    ];
    setChartDateData(data);
    return filteredData;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {department ? (
            <Typography
              sx={{
                fontSize: 14,
                paddingTop: 2,
                textAlign: "left",
              }}
              color="text.secondary"
              gutterBottom
            >
              {department.name}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <CustomFormControl>
            <InputLabel id="demo-simple-select-label">Metric</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Department"
              onChange={(e) => handleMetricChange(e.target.value)}
              value={metricId ? metricId : ""}
            >
              {metrics.map((metric: MetricA, i) => (
                <MenuItem key={i} value={metric.id}>
                  {metric?.name?.substring(0, 75)}
                </MenuItem>
              ))}
            </Select>
          </CustomFormControl>
        </Grid>

        <Grid item xs={6}>
          <CustomMobileDatePicker
            label="From"
            value={from}
            maxDate={new Date()}
            onChange={handleFromChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomMobileDatePicker
            maxDate={new Date()}
            label="To"
            value={to}
            onChange={handleToChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Box ml={2.5} mt={2}>
          <Button
            size="small"
            variant={Duration?.w ? "contained" : "outlined"}
            onClick={() =>
              setDuration(() => ({ w: true, m: false, y: false, y_5: false }))
            }
          >
            1w
          </Button>
          <Button
            size="small"
            variant={Duration?.m ? "contained" : "outlined"}
            sx={{ marginLeft: "50px" }}
            onClick={() =>
              setDuration(() => ({ w: false, m: true, y: false, y_5: false }))
            }
          >
            1M
          </Button>
          <Button
            size="small"
            variant={Duration?.y ? "contained" : "outlined"}
            sx={{ marginLeft: "50px" }}
            onClick={() =>
              setDuration(() => ({ w: false, m: false, y: true, y_5: false }))
            }
          >
            1Y
          </Button>
          <Button
            size="small"
            variant={Duration?.y_5 ? "contained" : "outlined"}
            sx={{ marginLeft: "50px" }}
            onClick={() =>
              setDuration(() => ({ w: false, m: false, y: false, y_5: true }))
            }
          >
            5Y
          </Button>
        </Box>
      </Grid>
      <div
        style={{
          width: "105%",
          height: 380,
          marginLeft: -37,
          marginTop: 30,
          marginBottom: 80,
        }}
      >
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={350}
            data={chartDateData}
            margin={{
              top: 10,
              right: 10,
              left: 10,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="updateDateTime"
              angle={-60}
              height={90}
              textAnchor="end"
              interval={0}
              ticks={chartLabelData.map((item: any) => item.updateDateTime)}
            />
            <YAxis type="number" domain={[0, "dataMax "]} />
            <Tooltip />

            <Line
              type="step"
              dataKey="value"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
