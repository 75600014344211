import { useEffect, useState } from "react";
import MetricAInfo from "../../../features/metrics/components/MetricAInfo";
import { MetricA } from "../../../features/metrics/models/metric";
import metricsAService from "../../../features/metrics/services/metricsA.service";
import globalStyles from "../../../global.module.css";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
import { CustomTextField } from "../../../shared/CustomTextField";
export default function MetricsDashboard() {
  const [metrics, setMetrics] = useState<MetricA[]>([]);
  const [filteredMetrics, setFilteredMetrics] = useState<MetricA[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [timer, setTimer] = useState<any>(null);
  const { setLoading } = useLoader();
  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setLoading(true);
    let metricsA: any[] = await metricsAService.getAll();
    setMetrics(metricsA);
    setFilteredMetrics(metricsA);
    setLoading(false);
  };

  const handleDebouncedSearch = (value: any) => {
    clearTimeout(timer);
    let t = setTimeout(() => {
      setTimer(t);
      const trimmedValue = value.trim();
      if (trimmedValue !== '') {
        const m = metrics.filter((metric: MetricA) => {
          return metric.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredMetrics(m);
      }
    }, 500);
  };

  return (
    <>
      <div className={globalStyles.pageContainer} style={{marginBottom:'80px'}}>
        <h1 style={{marginBottom:"10px"}}>Metric</h1>
        <CustomTextField
          label="Search Metric"
          type="text"
          name="name"
          onChange={(e) => handleDebouncedSearch(e.target.value.trim())}
          fullWidth
        />
        {filteredMetrics.length === 0 && searchString === "" && (
          <div className="text-center">You don't have metrics assigned</div>
        )}

        {filteredMetrics.map((metric: MetricA, i) => (
          <MetricAInfo
            key={i}
            metric={metric}
            onChartClick={() => {}}
            onShareClick={() => {}}
            department={metric?.departmentId}
          />
        ))}
      </div>
    </>
  );
}
