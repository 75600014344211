import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton } from "@mui/material";
import React from "react";
import { useUserAuth } from "../../../AuthContext";
import { useToast } from "../../../layout/toast/ToastProvider";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import usersDepartmentService from "../services/usersDepartment.service";
import styles from "./userRoles.module.css";

type UserRolItemProps = {
  userRol: any;
  updated: () => void;
  canDelete: boolean;
  departmentName: string;
};

export const UserRolItem = ({ userRol, updated, canDelete, departmentName }: UserRolItemProps) => {
  const user: any = useUserAuth();
  const { toastShow } = useToast();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);

  const remove = async () => {
    await usersDepartmentService.delete(userRol.id);
    toastShow({
      message: `The invitation to department ${departmentName} by ${userRol.email} deleted succesfully !`,
      severity: "success",
    });
    updated();
  };

  const openConfirmationDialog = () => {
    setOpenDeleteConfirmation(true);
  };

  return (
    <>
    <div className={styles.userRolItem}>
        <div className={styles.email}>{userRol.email}</div>
        {userRol.rol ? <div className={styles.rol}>{userRol.rol}</div>:null}
          <div className={styles.delete}>        
        {userRol.email !== user.email && userRol.rol !== "OWNER" && canDelete ? (
          <IconButton className={styles.deletebutton} aria-label="share" onClick={openConfirmationDialog}>
            <DeleteOutlinedIcon />
          </IconButton>
        ) : null}
          </div>
      </div>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false);
        }}
        message={"Are you sure to delete the user ?"}
        onAccept={() => {
          setOpenDeleteConfirmation(false);
          remove();
        }}
      />
    </>
  );
};
