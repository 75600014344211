import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import globalStyle from "../../global.module.css";
import { OutlinedButton } from "../../shared/OutlinedButton";
import FormLogin from "./FormLogin";

function Login() {
  const auth = useAuth();

  const [token, setToken] = React.useState<string>("");

  return (
    <div className={globalStyle.pageContainer}>
      <span className={globalStyle.ellipse2}></span>
      <FormLogin />
      <div>
        <div>
          <h6>
            <span>or</span>
          </h6>
        </div>
        <div>
          <Link to={"/signUp"}>
            <OutlinedButton type="submit"> Register</OutlinedButton>
          </Link>
        </div>
      </div>
      <span className={globalStyle.ellipse3}></span>
      <span className={globalStyle.ellipse4}></span>
    </div>
  );
}

export default Login;
