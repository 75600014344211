import CloseIcon from "@mui/icons-material/Close";
import EditOutlined from "@mui/icons-material/EditOutlined";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorFormMsg } from "../../common/ErrorMessage";
import { useToast } from "../../layout/toast/ToastProvider";
import CustomDialog, {
  BottomDialog,
  Transition,
} from "../../shared/CustomDialog";
import { CustomTextField } from "../../shared/CustomTextField";
import { OutlinedButton } from "../../shared/OutlinedButton";
import { PrimaryButton } from "../../shared/PrimaryButton";
import TemplateGroupsSelect from "./TemplateGroupsSelect";
import { Template, TemplateGroup } from "./models/models";
import templateService from "./services/template.service";
import TemplatesGroupsService from "./services/templatesGroups.service";
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (templates: Template[]) => void;
  refresh: () => void;
  canEdit: boolean;
};

export default function TemplateSelectorDialog(props: Props) {
  const [groupselct, setgroupselct] = useState<boolean>(false);
  const [allTemplates, setAllTemplates] = React.useState<Template[]>([]);
  const [selectedTemplates, setSelectedTemplates] = React.useState<Template[]>(
    []
  );
  const [selectedGroups, setselectedGroups] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const { toastShow } = useToast();

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const getTemplates = (ids: string[]) => {
    TemplatesGroupsService.getAllTemplatesFrom(ids).then((resp: any) => {
      if (resp.data) {
        setSelectedTemplates(resp.data);
      } else {
        setSelectedTemplates([]);
      }
    });
  };

  useEffect(() => {
    if (props.isOpen)
      templateService.get().then((resp: any) => {
        if (resp.data) {
          setAllTemplates(resp.data);
          setSelectedTemplates(resp.data);
        }
      });
    setChecked([]);
  }, [props.isOpen]);

  const HandleClose = () => {
    setOpen(false);
    reset();
  };

  const addGroups = (groups: TemplateGroup[]) => {
    setValue(
      "groups",
      groups.map((g) => g.id)
    );
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const HandleEditDialougeBox = (template: any) => {
    setOpen(true);
    setValue("name", template?.name);
    setValue("isGlobal", "true");
    setValue("data", template?.data);
    setValue("id", template?.id);
    const updatedArray: any = template?.groups?.map((item: any) => item.id);
    setselectedGroups(updatedArray);
  };

  const onSubmit = async (data: any) => {
    await templateService.update(data).then((resp: AxiosResponse) => {
      toastShow({
        message: "Template " + data.name + " saved succesfully !",
        severity: "success",
      });
    });
    HandleClose();
    templateService.get().then((resp: any) => {
      if (resp.data) setAllTemplates(resp.data);
    });
  };

  useEffect(() => {
    if (watch("groups")?.length > 0) {
      setgroupselct(true);
    } else {
      setgroupselct(false);
    }
  }, [watch("groups")]);

  return (
    <>
      <CustomDialog
        open={props.isOpen}
        onClose={props.onClose}
        title="Select Template"
        content={
          <Grid container>
            <TemplateGroupsSelect
              onSelect={function (templates: TemplateGroup[]): void {
                setChecked([]);
                if (templates.length > 0) {
                  getTemplates(templates.map((t) => t.id!));
                } else {
                  setSelectedTemplates(allTemplates);
                }
              }}
              isEditable={false}
              label={"Select group"}
            />
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {selectedTemplates.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem
                    key={value.id}
                    disablePadding
                    secondaryAction={
                      props?.canEdit ? (
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={() => HandleEditDialougeBox(value)}
                        >
                          <EditOutlined />
                        </IconButton>
                      ) : (
                        ""
                      )
                    }
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value.id!)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value.id!) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={value?.name?.substring(0, 75)}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        }
        footer={
          <>
            <OutlinedButton
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </OutlinedButton>
            <PrimaryButton
              form="formMetric"
              onClick={() => {
                let templates = allTemplates.filter((t) =>
                  checked.includes(t.id!)
                );
                props.onSelect(templates);
                props.onClose();
              }}
              disabled={checked.length > 0 ? false : true}
            >
              Save
            </PrimaryButton>
          </>
        }
      ></CustomDialog>
      <BottomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={HandleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Edit Template
          <IconButton onClick={HandleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} mb={3}>
              <Typography
                sx={{ fontSize: 14, marginBottom: 2 }}
                color="text.secondary"
                gutterBottom
              >
                Template Name
              </Typography>
              <Controller
                name="name"
                control={control}
                defaultValue={""}
                rules={{
                  required: { value: true, message: "This field is required" },
                  validate: {
                    notEmpty: (value) =>
                      value.trim() !== "" || "This field is required",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label=""
                    type="text"
                    error={Boolean(errors.name)}
                    {...field}
                  />
                )}
              />
              <TextField name="data" sx={{ display: "none" }} />
              <TextField name="id" sx={{ display: "none" }} />
              {errors?.name && <ErrorFormMsg message={errors?.name?.message} />}
            </Grid>
            <Controller
              name="groups"
              control={control}
              defaultValue={""}
              rules={{
                validate: (value) => {
                  return value && value.length > 0
                    ? true
                    : "Please select at least one group";
                },
              }}
              render={({ field }) => (
                <>
                  <TemplateGroupsSelect
                    onSelect={function (
                      templatesGroups: TemplateGroup[]
                    ): void {
                      addGroups(templatesGroups);
                    }}
                    defaultvalue={selectedGroups}
                    isEditable={true}
                    label={"Add to group"}
                    {...field}
                    isError={Boolean(errors.groups)}
                  />
                </>
              )}
            />
            {groupselct === false && (
              <ErrorFormMsg message={errors?.groups?.message} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={HandleClose}>Cancel</OutlinedButton>
          <PrimaryButton form="formMetric" onClick={handleSubmit(onSubmit)}>
            Save
          </PrimaryButton>
        </DialogActions>
      </BottomDialog>
    </>
  );
}
