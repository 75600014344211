import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth, useUserAuth } from "../../../AuthContext";
import { Department } from '../../../features/departments/models/department';
import departmentsService from '../../../features/departments/services/departments.service';
import MyProfileDepartMentCard from '../../../features/myprofile/components/Departmentcard';
import globalStyles from "../../../global.module.css";
import { useLoader } from "../../../layout/laoder/LoaderProvider";
export default function MyProfile() {
  const auth = useAuth();
  const { userInfo } = useUserAuth();
  const { setLoading } = useLoader();
  const [allDepartments, setAllDepartments] =useState<Department[]>([]);
  
  useEffect(() => {
    if (userInfo && auth) refresh();
  }, [userInfo, auth]);

  const refresh = async () => {
    if (!userInfo || !auth) return;
    setLoading(true);
    let allDepartments: any[] = await departmentsService.getAll();
    if (allDepartments.length > 0) {
      setAllDepartments(allDepartments);
    }
    setLoading(false);
  };
  
  return (
    <>
      <div className={globalStyles.pageContainer} style={{marginBottom:"80px"}}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h1 style={{marginLeft:"31px"}}>My Profile</h1>
          </Grid>
          <Grid item xs={4}>
            <h4>Hi, <strong>{userInfo?.name?.split(" ")?.[0]}</strong></h4>
          </Grid>
        </Grid>
        <Card sx={{ maxWidth: 480, ml:4 , mt:1}} >
          <CardContent>
            <Typography>
              Name - {userInfo?.name}
            </Typography>
            <Typography mt={1} >
               Email -{userInfo?.email}
            </Typography>
          </CardContent>
            <Box pl={1.7} pb={2} color={'#6495ed'}><Grid item xs={1}>
          <IconButton >
            <EditOutlinedIcon className={globalStyles.EditOutlinedIcon} />
            </IconButton>
            </Grid></Box>
        </Card>
        <Grid container spacing={2} mt={1} mb={1}>
          <Grid item xs={8}>
            <h1 style={{marginLeft:"31px"}}>My Departments</h1>
          </Grid>
        </Grid>
       {allDepartments.length > 0 && allDepartments.map((d, i) => {
        return (
          <MyProfileDepartMentCard
            key={i}
            department={d}
          />
       )})
      }
      </div>
    </>
  );
}