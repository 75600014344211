import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton } from "@mui/material";
import React from "react";
import { useToast } from "../../layout/toast/ToastProvider";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import styles from "./invitations.module.css";
import { Invitation } from "./models/invitation";
import invitationsService from "./services/invitations.service";

type InvitationItemProps = {
  invitation: Invitation;
  updated: () => void;
  canDelete: boolean;
  departmentName:string;
};

type InvitationItemState = {
  openDeleteConfirmation: boolean;
};

export default function InvitationItem(props: InvitationItemProps) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);
    const { toastShow } = useToast();

  const delete_ = async () => {
    await invitationsService.delete(props.invitation.id);
    props.updated();
    toastShow({
      message: `The invitation to department ${props?.departmentName} by ${props.invitation.userReceivingEmail} deleted succesfully !`,
      severity: "success",
    });
  };

  return (
    <div className={styles.invitationItem}>
      <div className={styles.email}>{props.invitation.userReceivingEmail}</div>
      <div className={styles.rol}>{props.invitation.rol}</div>
      <div className={styles.status}>{props.invitation.status}</div>
      {props?.invitation.status === "PENDING" && props?.canDelete ? (
        <>
          <div className={styles.delete}>
            <IconButton
              aria-label="share"
              onClick={() => setOpenDeleteConfirmation(true)}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </div>
          <ConfirmationDialog
            open={openDeleteConfirmation}
            onClose={() => {
              setOpenDeleteConfirmation(false);
            }}
            message={
              "Are you sure to delete the invitation to " +
              props.invitation.userReceivingEmail +
              "?"
            }
            onAccept={() => {
              setOpenDeleteConfirmation(false);

              delete_();
            }}
          />
        </>
      ) : null}
    </div>
  );
}
