import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import authService from "../../features/auth/auth.service";

export default function VerificationCode() {
  const [code, setCode] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [paramFilled, setParamFilled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [method, setMethod] = useState("code");

  const [error, setError] = useState("");

  useEffect(() => {
    if (searchParams.get("email")) {
      const params:any = searchParams.get("email")?.split(' ');
      let curr_email = "";
      if(params.length>1){
        curr_email = params[0]+"+"+params[1];
        setUsername(curr_email as string);
      }else{
        setUsername(searchParams.get("email") as string);
      }
      setMethod(searchParams.get("method") as string);
      if (searchParams.get("method") === "code") {
        setError(
          `We have sent you a verification code to  ${username}, please check your email and click on the link to confirm your account.`
        );
        setParamFilled(true);
      }
      if (searchParams.get("method") === "link") {
        setError(
          `We have sent you a verification link to  ${username}, please check your email and click on the link to confirm your account.`
        );
        setParamFilled(true);
      }
    } else {
      setError(
        "We have sent you a verification code, please check your email and enter it below to confirm your account."
      );
    }
  }, [username, code, method, paramFilled]);

  const submitCode = async (event: any) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    authService.confirmEmailCode(username, code).then((data: any) => {
      if (data.code === "ERR_BAD_REQUEST") {
        setError(data.response.data.message);
      } else {
        navigate("/login");
      }
    });

    // navigate("/login");
  };
  return (
    <>
      <div className="min-h-full flex items-center justify-center pt-12 pb-3 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-24 w-auto"
              src="/boc-orange.png"
              alt="boc logo"
            />
            <form className="mt-8 space-y-6" onSubmit={submitCode}>
              {error ? <ErrorMessage message={error} /> : null}

              {method === "code" ? (
                <>
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <TextField
                        id="username"
                        label="Email"
                        type="text"
                        size="small"
                        name="email"
                        value={username}
                        onChange={(e: any) => setUsername(e.target.value)}
                        fullWidth
                        disabled={paramFilled}
                      />
                    </div>
                  </div>

                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <TextField
                        id="verification-code"
                        label="Code"
                        type="text"
                        size="small"
                        name="email"
                        value={code}
                        onChange={(e: any) => setCode(e.target.value)}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div>
                    <Button type="submit" variant="contained" fullWidth>
                      {" "}
                      Verify Code
                    </Button>
                  </div>
                </>
              ) : (
                <Link to={"/login"}>
                  <Button variant="contained" fullWidth>
                    Go Back to Log In
                  </Button>
                </Link>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
