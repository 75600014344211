function ErrorMessage({ message }: { message: string }) {
    return (
        <div className="bg-transparent border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">{ message }</strong>
        </div>
    )
}

export default ErrorMessage

function ErrorFormMsg({ message }: { message: any }) {
    return (
        <div className="bg-transparent text-red-500 px-1 py-1 rounded relative" role="alert">
            <strong style={{fontSize:"12px"}}>{message}</strong>
        </div>
    )
}
export { ErrorFormMsg }
